import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/image-user-hover.svg'
import _imports_1 from '@/assets/calendar-icon.svg'
import _imports_2 from '@/assets/ac-header-icon-1.svg'
import _imports_3 from '@/assets/ac-header-icon-2.svg'


const _hoisted_1 = { class: "laptop-custom:-ml-[88px] w-full h-screen flex flex-col md:flex-row items-start gap-3 overflow-y-auto" }
const _hoisted_2 = { class: "h-[calc(100vh-24px)] lg:h-[calc(100vh-46px)] max-w-[700px] xl:max-w-[800px] laptop-custom:max-w-[934px] w-full mx-auto overflow-y-auto" }
const _hoisted_3 = { class: "flex flex-col w-full sticky top-0 z-20 bg-[#efeeec]" }
const _hoisted_4 = { class: "flex flex-col md:flex-row items-start md:items-center justify-between mb-6" }
const _hoisted_5 = { class: "text-lg pb-3 md:pb-0 sm:text-[32px] text-primary-text-color leading-[41.5xp] tracking-[0.01rem] font-semibold" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "grid grid-cols-1 sm:grid-cols-2 gap-3" }
const _hoisted_9 = { class: "col-span-2 p-3 bg-white rounded-primary-radius shadow-primary-box-shadow" }
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col items-start gap-2"
}
const _hoisted_11 = { class: "text-base leading-5 font-normal tracking-[0.01rem] text-primary-text-color pb-2" }
const _hoisted_12 = { class: "max-w-[948px] overflow-x-auto overflow-y-auto flex items-center gap-3" }
const _hoisted_13 = { class: "w-[300px] h-[300px] hover:bg-[#efeeec] hover:p-3 flex-shrink-0 rounded-primary-radius" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "group-hover:block hidden absolute max-w-[300px] max-h-[300px] inset-0 bg-[#efeeec] p-3 rounded-primary-radius opacity-0 group-hover:opacity-100 cursor-pointer overflow-hidden" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "mt-2 flex flex-col items-start gap-1" }
const _hoisted_18 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_19 = { class: "flex items-center gap-1" }
const _hoisted_20 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_21 = { class: "flex items-center gap-1" }
const _hoisted_22 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_23 = { class: "block" }
const _hoisted_24 = { class: "relative w-[18px] h-[14px] cursor-pointer" }
const _hoisted_25 = {
  key: 0,
  class: "w-[310px] absolute top-6 right-0 z-[888]"
}
const _hoisted_26 = {
  key: 0,
  class: "w-full min-h-screen lg:w-[50%]"
}

import CustomAccordion from "@/components/Re-usable/CustomAccordion.vue";
import { ref } from "vue";
import TechnicalDetails from "@/components/Project/TechnicalDetails.vue";
import TerminFilter from "@/components/Re-usable/TerminFilter.vue";
import ProjectMap from "@/components/Project/ProjectMap.vue";
import QCModal from "@/components/PhaseDetails/QCModal.vue";

type Props = {
  phaseDetails: any;
  phaseId: number;
  projectId: string;
};

type Emits = {
  (event: "refreshData"): void;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'QcView',
  props: {
    phaseDetails: {},
    phaseId: {},
    projectId: {}
  },
  emits: ["refreshData"],
  setup(__props: any) {

const props = __props;



const isModal = ref(false);
const isTerminFilterOpen = ref(false);
const isMap = ref(false);

const toggleModalHandler = () => {
  isModal.value = !isModal.value;
};

const toggleProjectMap = () => {
  isMap.value = !isMap.value;
};

const selectedTermins = ref(
  props.phaseDetails.termins.map((item: any) => item.id)
);

const onFilterSubmit = (termins: Array<string>) => {
  selectedTermins.value = [...termins];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.phaseDetails.project.name), 1),
          _createElementVNode("button", {
            onClick: toggleModalHandler,
            class: "text-base text-white font-semibold leading-5 tracking-[0.01rem] p-3 bg-[#393840] rounded-primary-radius outline-none hover:bg-[#202022] duration-300"
          }, " QC complete ")
        ]),
        (isModal.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(QCModal, {
                "phase-id": _ctx.phaseId,
                "project-id": _ctx.projectId,
                "is-modal-open": isModal.value,
                onToggleModal: toggleModalHandler,
                onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refreshData')))
              }, null, 8, ["phase-id", "project-id", "is-modal-open"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(TechnicalDetails, {
            project: _ctx.phaseDetails.project
          }, null, 8, ["project"])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phaseDetails.steps, (step) => {
        return (_openBlock(), _createElementBlock("div", {
          key: step.id,
          class: "mt-[36px]"
        }, [
          _createVNode(CustomAccordion, {
            "step-id": step.id,
            "step-name": step.name
          }, {
            default: _withCtx(() => [
              (step.inputs.some((input) => input.type === 'image' && input.value.length > 0))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold" }, " Uploaded pictures ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => { return input.type === 'image'}), (input) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: input.id
                          }, [
                            (input.value.length > 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("h4", _hoisted_11, _toDisplayString(input.name), 1),
                                    _createElementVNode("div", _hoisted_12, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(input.value.filter((image)=> image.terminId === undefined || selectedTermins.value.includes(image.terminId)), (image) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: image.id,
                                          class: "flex-shrink-0"
                                        }, [
                                          _createElementVNode("div", _hoisted_13, [
                                            _createElementVNode("img", {
                                              class: "w-full h-full object-cover rounded-primary-radius",
                                              src: image.url,
                                              alt: "Project image"
                                            }, null, 8, _hoisted_14),
                                            _createElementVNode("div", _hoisted_15, [
                                              _createElementVNode("img", {
                                                class: "w-full h-[200px] object-cover rounded-primary-radius",
                                                src: image.url,
                                                alt: "Project image"
                                              }, null, 8, _hoisted_16),
                                              _createElementVNode("div", _hoisted_17, [
                                                _createElementVNode("p", _hoisted_18, _toDisplayString(image.terminName), 1),
                                                _createElementVNode("div", _hoisted_19, [
                                                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                                                    src: _imports_0,
                                                    alt: ""
                                                  }, null, -1)),
                                                  _createElementVNode("p", _hoisted_20, _toDisplayString(image.worker), 1)
                                                ]),
                                                _createElementVNode("div", _hoisted_21, [
                                                  _cache[4] || (_cache[4] = _createElementVNode("img", {
                                                    class: "w-4 h-4",
                                                    src: _imports_1,
                                                    alt: ""
                                                  }, null, -1)),
                                                  _createElementVNode("p", _hoisted_22, _toDisplayString(new Date(
                                        image.createdAt
                                      ).toLocaleDateString("DE")), 1)
                                                ])
                                              ])
                                            ])
                                          ])
                                        ]))
                                      }), 128))
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["step-id", "step-name"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", {
        class: _normalizeClass(["xl:absolute xl:top-[24px] xl:right-[24px] flex items-center gap-3 justify-end", isMap.value ? 'hidden' : 'block'])
      }, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("img", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isTerminFilterOpen.value = !isTerminFilterOpen.value)),
            class: "w-full h-full object-cover",
            src: _imports_2,
            alt: ""
          }),
          (isTerminFilterOpen.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createVNode(TerminFilter, {
                  project: _ctx.phaseDetails,
                  onCloseTerminFilter: _cache[2] || (_cache[2] = ($event: any) => (isTerminFilterOpen.value = false)),
                  onOnFilterSubmit: onFilterSubmit
                }, null, 8, ["project"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (props.phaseDetails.project.baummape !== null)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: toggleProjectMap,
              class: "w-[18px] h-[18px] cursor-pointer"
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("img", {
                class: "w-full h-full object-cover",
                src: _imports_3,
                alt: ""
              }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (isMap.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createVNode(ProjectMap, {
            "phase-details": props.phaseDetails,
            onToggleProjectMap: toggleProjectMap
          }, null, 8, ["phase-details"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})