<script setup lang="ts">
import { Project } from "@/types";
import { ref, defineProps } from "vue";

type Props = {
  project: Project;
};

defineProps<Props>();

const showDetails = ref(true);
</script>

<template>
  <div class="bg-[#DFDEDC] rounded-primary-radius">
    <div
      @click="showDetails = !showDetails"
      class="flex items-center justify-between p-3 cursor-pointer"
    >
      <h2
        class="text-lg sm:text-2xl leading-[30px] tracking-[0.01rem] text-primary-text-color font-semibold"
      >
        Technical details
      </h2>
      <img
        class="cursor-pointer"
        :class="showDetails ? 'rotate-0' : 'rotate-180'"
        src="@/assets/chevron-up.svg"
        alt=""
      />
    </div>
    <div
      v-if="showDetails"
      class="flex flex-col lg:flex-row items-start p-3 pt-1 gap-3"
    >
      <!-- Left -->
      <div class="flex-1 flex flex-col items-start gap-2">
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            VNB
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.vnb ? project?.vnb : "-" }}
          </p>
        </div>
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            Wechselrichter
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.wechselrichter ? project?.wechselrichter : "-" }}
          </p>
        </div>
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            Speicher
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.speicher ? project?.speicher : "-." }}
          </p>
        </div>
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            Masskonzept
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.masskonzept ? project?.masskonzept : "-" }}
          </p>
        </div>
      </div>
      <!-- Right -->
      <div class="flex-1 flex flex-col items-start gap-2">
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            WB
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.wb ? project?.wb : "-" }}
          </p>
        </div>
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            Number of Modules
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.numberOfModules ? project?.numberOfModules : "-" }}
          </p>
        </div>
        <div>
          <h4
            class="text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            Modul Type
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-norma pt-1"
          >
            {{ project?.modulType ? project?.modulType : "-" }}
          </p>
        </div>
        <div>
          <h4
            class="text-sm font-sans font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]"
          >
            IMB Date
          </h4>
          <p
            class="text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1"
          >
            {{ project?.imbDate ? project?.imbDate : "-" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
