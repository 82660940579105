<script setup lang="ts"></script>

<template>
  <div
    class="w-full min-h-screen bg-[url('@/assets/login-page-bg.png')] bg-cover bg-center bg-no-repeat flex items-center justify-start sm:items-start sm:pt-[218px] px-[24px] md:pl-[115px]"
  >
    <div>
      <slot />
    </div>
  </div>
</template>
