<script lang="ts" setup>
import "/custom.css";
import SunIcon from "@/assets/sun-icon.svg";
import BlocksIcon from "@/assets/this-week-icon.svg";
import FileIcon from "@/assets/file-icon.svg";
import DoublePlay from "@/assets/double-play.svg";
import MainLayout from "@/layouts/MainLayout.vue";
import MainContent from "@/components/MainPage/MainContent.vue";
import { computed, ref, onMounted, watch } from "vue";
import client from "@/utils/axios";
import { textTransform } from "@/utils/parse-data";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { Status } from "@/types";
import SpinnerLoader from "@/components/Loaders/SpinnerLoader.vue";

const isSelectedMenu = ref(false);
const searchQuery = ref("");
const isLoading = ref(false);

const isSelectMenuHandler = () => {
  isSelectedMenu.value = !isSelectedMenu.value;
};

// Fetch data
const allStatuses = ref<Status[]>([]);
const aLLProjects = ref<any>([]);
const activeFilter = ref<any>();

const getAllStatuses = async () => {
  try {
    const response = await client.get("/project-statuses");
    if (response.data) {
      let statuses = response.data.map((item: any) => {
        return {
          ...item,
          isSelected: true,
          isOpen: true,
        };
      });
      allStatuses.value = statuses;
    }
  } catch (error) {
    console.log(error);
  }
};

const getAllProjects = async () => {
  isLoading.value = true;

  try {
    const response = await client.get("/projects");
    if (response.data) {
      aLLProjects.value = response.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const toggleStatusOpen = (statusId: number) => {
  const status = allStatuses.value.find(
    (status: any) => status.id === statusId
  );

  if (status) {
    status.isOpen = !status.isOpen;
  }
};

onMounted(() => {
  getAllStatuses();
  getAllProjects();
});

const filteredProjects = computed(() => {
  let results = aLLProjects.value;
  switch (activeFilter.value) {
    case "today":
      {
        results = results.filter((project: any) => {
          const currentDateFull = new Date();
          const currentDate = new Date(
            currentDateFull.getFullYear(),
            currentDateFull.getMonth(),
            currentDateFull.getDate()
          );
          let match = false;

          project.termins?.forEach((termin: any) => {
            let start = null;
            let end = null;

            if (termin.start) {
              start = new Date(termin.start);
              start?.setHours(0, 0, 0, 0);
            }
            if (termin.end) {
              end = new Date(termin.end);
              end?.setHours(0, 0, 0, 0);
            }

            const projectStartToday = start
              ? start >= currentDate && start <= currentDate
              : false;

            const projectEndToday = end
              ? end >= currentDate && end <= currentDate
              : false;

            const projectInProgress =
              start && end ? start < currentDate && end > currentDate : false;

            if (projectStartToday || projectEndToday || projectInProgress) {
              match = true;
            }
          });

          return match;
        });
      }
      break;
    case "week":
      results = results.filter((project: any) => {
        const currentDateFull = new Date();
        const currentDate = new Date(
          currentDateFull.getFullYear(),
          currentDateFull.getMonth(),
          currentDateFull.getDate()
        );
        let match = false;

        project.termins?.forEach((termin: any) => {
          let start = null;
          let end = null;

          if (termin.start) {
            start = new Date(termin.start);
            start?.setHours(0, 0, 0, 0);
          }
          if (termin.end) {
            end = new Date(termin.end);
            end?.setHours(0, 0, 0, 0);
          }

          const day = currentDate.getDay();
          const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
          const monday = new Date(currentDate.setDate(diff));
          const sunday = new Date(currentDate.setDate(diff + 6));

          const projectStartThisWeek = start
            ? start >= monday && start <= sunday
            : false;

          const projectEndThisWeek = end
            ? end >= monday && end <= sunday
            : false;

          const projectInProgress =
            start && end ? start < currentDate && end > currentDate : false;

          if (projectStartThisWeek || projectEndThisWeek || projectInProgress) {
            match = true;
          }
        });

        return match;
      });
      break;
    case "abnahme":
      results = results.filter((project: any) => {
        return (
          project.phases?.filter((item: any) => item.status.id === 4).length > 0
        );
      });
      break;
    case "qc":
      results = results.filter((project: any) => {
        return (
          project.phases?.filter((item: any) => item.status.id === 5).length > 0
        );
      });
      break;
  }

  if (searchQuery.value) {
    results = results.filter((query: any) => {
      return query.name.toLowerCase().includes(searchQuery.value.toLowerCase());
    });
  }

  return results;
});

const setActiveFilter = (filter: string) => {
  if (activeFilter.value === filter) {
    activeFilter.value = null;
  } else {
    activeFilter.value = filter;
  }
};
</script>
<template>
  <MainLayout>
    <div class="flex items-start">
      <div>
        <MainSidebar :phase-progress="[]" :phase-steps="[]" />
      </div>
      <div class="w-full h-[calc(100vh-40px)] flex flex-col items-center">
        <!-- Header -->
        <div
          class="w-full flex flex-col gap-5 mb-6 lg:flex-row items-center justify-center lg:items-start lg:justify-between"
        >
          <div class="hidden sm:block"></div>
          <div>
            <div class="flex flex-wrap items-center justify-center gap-3">
              <!-- Header menu items -->
              <button
                @click="setActiveFilter('today')"
                id="today"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="
                  activeFilter === 'today' ? 'bg-white' : 'bg-transparent'
                "
              >
                <img :src="SunIcon" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">Today</p>
              </button>
              <button
                @click="setActiveFilter('week')"
                id="week"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="activeFilter === 'week' ? 'bg-white' : 'bg-transparent'"
              >
                <img :src="BlocksIcon" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">
                  This week
                </p>
              </button>
              <button
                @click="setActiveFilter('abnahme')"
                id="abnahme"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="
                  activeFilter === 'abnahme' ? 'bg-white' : 'bg-transparent'
                "
              >
                <img :src="FileIcon" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">
                  Abnahme ready
                </p>
              </button>
              <button
                @click="setActiveFilter('qc')"
                id="qc"
                class="flex gap-1 items-center p-2 cursor-pointer rounded-md hover:bg-white duration-300"
                :class="activeFilter === 'qc' ? 'bg-white' : 'bg-transparent'"
              >
                <img :src="DoublePlay" alt="Sun icon" />
                <p class="text-sm font-normal text-primary-text-color">
                  QC ready
                </p>
              </button>
              <!-- Search -->
              <form
                class="w-[122px] flex items-center gap-2 px-2 bg-white rounded-lg"
              >
                <img src="@/assets/search-icon.svg" alt="" />
                <input
                  v-model="searchQuery"
                  class="w-full h-9 text-sm text-normal bg-transparent focus:outline-none"
                  type="text"
                  placeholder="Search"
                />
              </form>
            </div>
          </div>
          <!-- Filter -->
          <div class="flex items-center gap-[30px]">
            <div class="custom-select">
              <div @click="isSelectMenuHandler" class="selected">
                <p class="text-sm text-primary-text-color font-normal">
                  Filter status
                </p>
                <div>
                  <img
                    class="duration-200"
                    :class="isSelectedMenu ? '-rotate-180' : 'rotate-0'"
                    src="@/assets/chevron-down.svg"
                    alt=""
                  />
                </div>
              </div>
              <div
                v-show="isSelectedMenu"
                class="absolute flex flex-col gap-3 top-7 z-50 min-w-[170px] bg-white p-4 rounded-lg shadow-sm"
              >
                <div
                  v-for="column in allStatuses"
                  :key="column.id"
                  class="select-items"
                >
                  <label>
                    <p
                      class="flex items-center gap-2 text-sm text-primary-text-color font-normal cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        v-model="column.isSelected"
                        :class="column.isSelected ? 'hidden' : 'block'"
                      />
                      <img
                        v-show="column.isSelected"
                        :class="column.isSelected ? 'bg-[#c6c7f8]' : 'bg-white'"
                        src="@/assets/checkbox-checked.svg"
                        alt=""
                      />
                      {{ textTransform(column.name) }}
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <!-- Refresh projects -->
            <div>
              <button
                @click="getAllProjects"
                class="flex items-center gap-2 bg-transparent outline-none p-2 cursor-pointer rounded-md hover:bg-white duration-300"
              >
                <img
                  src="@/assets/refresh-icon.svg"
                  alt=""
                  :class="isLoading ? 'animate-spin' : 'animate-none'"
                />
                <p class="text-sm text-primary-text-color font-normal">
                  Refresh
                </p>
              </button>
            </div>
          </div>
        </div>
        <div v-if="isLoading" class="mt-28">
          <SpinnerLoader />
        </div>
        <div class="self-center lg:self-end 3xl:self-center" v-else>
          <MainContent
            :status-list="allStatuses"
            :project-list="filteredProjects"
            @toggle-status-open="toggleStatusOpen"
          />
        </div>
      </div>
    </div>
  </MainLayout>
</template>
