export const textTransform = (data: string) => {
  if (data) {
    const formattedString = data.replace(/_/g, " ");
    const result = formattedString
      .charAt(0)
      .toUpperCase()
      .concat(formattedString.slice(1));

    if (result.length <= 3) {
      return result.toUpperCase();
    }

    return result;
  }
};
