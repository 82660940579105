import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/user-details.svg'
import _imports_1 from '@/assets/phone-icon.svg'
import _imports_2 from '@/assets/message-icon.svg'
import _imports_3 from '@/assets/navigation.svg'


const _hoisted_1 = { class: "w-full h-full mb-3 flex flex-col lg:flex-row items-start gap-3" }
const _hoisted_2 = { class: "w-full lg:w-[72%] h-full bg-white p-3 rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row items-start gap-3" }
const _hoisted_4 = { class: "flex-1 flex flex-col items-start gap-2" }
const _hoisted_5 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_6 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_7 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_8 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_9 = { class: "flex-1 flex flex-col items-start gap-2" }
const _hoisted_10 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_11 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_12 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_13 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-normal pt-1" }
const _hoisted_14 = { class: "w-[35%] bg-white p-3 rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_15 = { class: "flex flex-col items-start gap-4" }
const _hoisted_16 = { class: "flex items-center gap-2 pb-2 border-b border-[#F1F0F5] w-full" }
const _hoisted_17 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-norma" }
const _hoisted_18 = { class: "flex items-center gap-2 pb-2 border-b border-[#F1F0F5] w-full" }
const _hoisted_19 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-norma" }
const _hoisted_20 = { class: "flex items-center gap-2 pb-2 border-b border-[#F1F0F5] w-full" }
const _hoisted_21 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-norma" }
const _hoisted_22 = { class: "flex items-center gap-2 pb-2 border-b border-[#F1F0F5] w-full" }
const _hoisted_23 = { class: "text-base leading-[20px] tracking-[0.01rem] text-primary-text-color font-norma" }



type Props = {
  vnb: string | null | undefined;
  wb: string | null | undefined;
  wechselrichter: string | null | undefined;
  speicher: string | null | undefined;
  masskonzept: string | null | undefined;
  numberOfModules: number | null | undefined;
  modulType: string | null | undefined;
  ibnDate: Date | null | undefined;
  homeOwnerName: string | null | undefined;
  homeOwnerAddress: string | null | undefined;
  homeOwnerPhone: string | null | undefined;
  emailAddress: string | null | undefined;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'BasicDetails',
  props: {
    vnb: {},
    wb: {},
    wechselrichter: {},
    speicher: {},
    masskonzept: {},
    numberOfModules: {},
    modulType: {},
    ibnDate: {},
    homeOwnerName: {},
    homeOwnerAddress: {},
    homeOwnerPhone: {},
    emailAddress: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "text-xl pb-2 text-primary-text-color tracking-[0.01rem] font-semibold" }, " Technical details ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " VNB ", -1)),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.vnb ? _ctx.vnb : "-"), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Wechselrichter ", -1)),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.wechselrichter ? _ctx.wechselrichter : "-"), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Speicher ", -1)),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.speicher ? _ctx.speicher : "-"), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "text-sm text-[#898790] leading-[17px] tracking-[0.01rem]" }, " masskonzept ", -1)),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.masskonzept ? _ctx.masskonzept : "-"), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", null, [
            _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " WB ", -1)),
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.wb ? _ctx.wb : "-"), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Number of Modules ", -1)),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.numberOfModules ? _ctx.numberOfModules : "-"), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " Modul Type ", -1)),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.modulType ? _ctx.modulType : "-"), 1)
          ]),
          _createElementVNode("div", null, [
            _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-sm font-semibold text-[#898790] leading-[17px] tracking-[0.01rem]" }, " IBN Date ", -1)),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.ibnDate ? _ctx.ibnDate : "-"), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "text-xl pb-4 text-primary-text-color tracking-[0.01rem] font-semibold" }, " Details ", -1)),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _cache[9] || (_cache[9] = _createElementVNode("img", {
            class: "w-4 h-4",
            src: _imports_0,
            alt: "User icon"
          }, null, -1)),
          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.homeOwnerName ? _ctx.homeOwnerName : "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[10] || (_cache[10] = _createElementVNode("img", {
            class: "w-4 h-4",
            src: _imports_1,
            alt: "User icon"
          }, null, -1)),
          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.homeOwnerPhone ? _ctx.homeOwnerPhone : "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _cache[11] || (_cache[11] = _createElementVNode("img", {
            class: "w-4 h-4",
            src: _imports_2,
            alt: "User icon"
          }, null, -1)),
          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.emailAddress ? _ctx.emailAddress : "-"), 1)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _cache[12] || (_cache[12] = _createElementVNode("img", {
            class: "w-4 h-4",
            src: _imports_3,
            alt: "User icon"
          }, null, -1)),
          _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.homeOwnerAddress ? _ctx.homeOwnerAddress : "-"), 1)
        ])
      ])
    ])
  ]))
}
}

})