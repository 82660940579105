import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[calc(100vh-42px)] pl-3 sm:pl-0 overflow-y-auto" }
const _hoisted_2 = { class: "max-w-[934px] mx-auto" }
const _hoisted_3 = { class: "flex flex-col items-start sm:flex-row sm:items-center justify-between" }
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = { class: "text-2xl sm:text-[32px] text-primary-text-color leading-[41.5px] tracking-[0.01rem] font-semibold" }
const _hoisted_6 = { class: "text-sm tracking-[0.01rem] text-primary-text-color px-[6px] py-[2px] bg-[#E3E1EA] rounded-primary-radius" }
const _hoisted_7 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-3" }

import { Project } from "@/types";
import BasicDetails from "./BasicDetails.vue";
import ProjectDocuments from "./ProjectDocuments.vue";
import TerminTimeline from "./TerminTimeline.vue";
import ThreeBlockRow from "./ThreeBlockRow.vue";

import { textTransform } from "@/utils/parse-data";

type Props = {
  projectDetails: Project | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectOverview',
  props: {
    projectDetails: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.projectDetails?.name), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.projectDetails ? _unref(textTransform)(_ctx.projectDetails?.status) : ""), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1))
      ]),
      _createElementVNode("div", null, [
        _createVNode(ThreeBlockRow, {
          "customer-name": _ctx.projectDetails ? _ctx.projectDetails?.name : '',
          "cost-carrier-code": 
            _ctx.projectDetails ? _ctx.projectDetails?.costCarrierCode + '' : ''
          ,
          meister: _ctx.projectDetails ? _ctx.projectDetails?.meister : 's',
          "next-termin": _ctx.projectDetails ? _ctx.projectDetails.nextTermin : ''
        }, null, 8, ["customer-name", "cost-carrier-code", "meister", "next-termin"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(BasicDetails, {
          vnb: _ctx.projectDetails?.vnb,
          wechselrichter: _ctx.projectDetails?.wechselrichter,
          speicher: _ctx.projectDetails?.speicher,
          masskonzept: _ctx.projectDetails?.masskonzept,
          wb: _ctx.projectDetails?.wb,
          "number-of-modules": _ctx.projectDetails?.numberOfModules,
          "modul-type": _ctx.projectDetails?.modulType,
          "ibn-date": _ctx.projectDetails?.ibnDate,
          "home-owner-name": _ctx.projectDetails?.homeownerName,
          "home-owner-address": _ctx.projectDetails?.homeownerAddress,
          "home-owner-phone": _ctx.projectDetails?.homeownerPhone,
          "email-address": _ctx.projectDetails?.ownerEmail
        }, null, 8, ["vnb", "wechselrichter", "speicher", "masskonzept", "wb", "number-of-modules", "modul-type", "ibn-date", "home-owner-name", "home-owner-address", "home-owner-phone", "email-address"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _createVNode(TerminTimeline, { projectDetails: _ctx.projectDetails }, null, 8, ["projectDetails"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(ProjectDocuments, { projectDetails: _ctx.projectDetails }, null, 8, ["projectDetails"])
        ])
      ])
    ])
  ]))
}
}

})