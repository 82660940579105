import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/red-x-mark.svg'
import _imports_1 from '@/assets/accordion-check-icon.svg'
import _imports_2 from '@/assets/image-user-hover.svg'
import _imports_3 from '@/assets/calendar-icon.svg'
import _imports_4 from '@/assets/ac-header-icon-1.svg'
import _imports_5 from '@/assets/ac-header-icon-2.svg'


const _hoisted_1 = { class: "flex flex-col w-full sticky top-0 z-20 bg-[#efeeec]" }
const _hoisted_2 = { class: "flex flex-col md:flex-row items-start md:items-center justify-between mb-6" }
const _hoisted_3 = { class: "text-lg pb-3 md:pb-0 sm:text-[32px] text-primary-text-color leading-[41.5xp] tracking-[0.01rem] font-semibold" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "flex flex-col gap-3" }
const _hoisted_6 = { class: "flex gap-3" }
const _hoisted_7 = {
  key: 0,
  class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
}
const _hoisted_8 = { class: "w-full list-none flex flex-col items-start gap-3" }
const _hoisted_9 = { class: "w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1" }
const _hoisted_10 = {
  key: 1,
  class: "p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
}
const _hoisted_11 = { class: "w-full list-none flex flex-col items-start gap-3" }
const _hoisted_12 = { class: "w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1" }
const _hoisted_13 = { class: "relative text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer" }
const _hoisted_14 = {
  key: 0,
  class: "col-span-2 p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
}
const _hoisted_15 = { class: "flex flex-col items-start gap-2 overflow-x-auto" }
const _hoisted_16 = { class: "text-base leading-5 font-normal tracking-[0.01rem] text-primary-text-color pb-2" }
const _hoisted_17 = { class: "max-w-[948px] overflow-x-auto overflow-y-auto flex items-center gap-3" }
const _hoisted_18 = ["href"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "group-hover:block hidden absolute max-w-[300px] max-h-[300px] inset-0 bg-[#efeeec] p-3 rounded-primary-radius opacity-0 group-hover:opacity-100 cursor-pointer overflow-hidden z-10" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "mt-2 flex flex-col items-start gap-1" }
const _hoisted_23 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_24 = { class: "flex items-center gap-1" }
const _hoisted_25 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_26 = { class: "flex items-center gap-1" }
const _hoisted_27 = { class: "text-sm text-primary-text-color font-normal" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "relative w-[18px] h-[14px] cursor-pointer" }
const _hoisted_30 = {
  key: 0,
  class: "w-[310px] absolute top-6 right-0 z-[888]"
}
const _hoisted_31 = {
  key: 1,
  class: "flex-1 min-h-screen w-[40%]"
}

import CustomAccordion from "@/components/Re-usable/CustomAccordion.vue";
import { ref } from "vue";
import TechnicalDetails from "@/components/Project/TechnicalDetails.vue";
import TerminFilter from "@/components/Re-usable/TerminFilter.vue";
import ProjectMap from "@/components/Project/ProjectMap.vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

type Props = {
  phaseDetails: any;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RegularView',
  props: {
    phaseDetails: {}
  },
  setup(__props: any) {

const route = useRoute();

const props = __props;
console.log(props.phaseDetails);

const isTerminFilterOpen = ref(false);
const isMap = ref(false);
const isLoading = ref(false);

const generateReport = async () => {
  isLoading.value = true;
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;

  let resp = await client.post(
    `/projects/${projectId}/phase/${phaseId}/generate-report`
  );
  if (resp && resp.status === 200) {
    toast.success("Report succesfully generated.");
  } else {
    toast.error("Something went wrong!");
  }
  isLoading.value = false;
};

const toggleProjectMap = () => {
  isMap.value = !isMap.value;
};

const selectedTermins = ref(
  props.phaseDetails.termins.map((item: any) => item.id)
);

const onFilterSubmit = (termins: Array<string>) => {
  selectedTermins.value = [...termins];
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["laptop-custom:-ml-[88px] w-full h-screen flex items-start gap-4 overflow-y-auto", isMap.value ? 'justify-self-end' : 'justify-self-start'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-[calc(100vh-24px)] lg:h-[calc(100vh-46px)] overflow-y-auto", `${
        isMap.value
          ? 'w-[60%]'
          : 'max-w-[700px] xl:max-w-[800px] laptop-custom:max-w-[934px] mx-auto'
      }`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.phaseDetails.project.name), 1),
          _createElementVNode("button", {
            disabled: isLoading.value,
            onClick: generateReport,
            class: "flex items-center gap-1 text-base text-white font-semibold leading-5 tracking-[0.01rem] p-3 bg-[#393840] rounded-primary-radius outline-none hover:bg-[#202022] duration-300"
          }, [
            (_openBlock(), _createElementBlock("svg", {
              width: "14",
              height: "16",
              viewBox: "0 0 14 16",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
              class: _normalizeClass(`${isLoading.value ? 'animate-spin' : ''}`)
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("path", {
                d: "M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179",
                stroke: "white",
                "stroke-width": "2",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }, null, -1)
            ]), 2)),
            _cache[3] || (_cache[3] = _createTextVNode(" Generate report "))
          ], 8, _hoisted_4)
        ]),
        _createElementVNode("div", null, [
          _createVNode(TechnicalDetails, {
            project: _ctx.phaseDetails.project
          }, null, 8, ["project"])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phaseDetails.steps, (step) => {
        return (_openBlock(), _createElementBlock("div", {
          key: step.id,
          class: "mt-[36px]"
        }, [
          _createVNode(CustomAccordion, {
            "step-id": step.id,
            "step-name": step.name
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (step.inputs.some((input) => input.type === 'checkbox'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold" }, " Checklist ", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => { return input.type === 'checkbox' && (input.terminId === undefined || selectedTermins.value.includes(input.terminId)) }), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "my-3"
                            }, [
                              _createElementVNode("ul", _hoisted_8, [
                                _createElementVNode("li", _hoisted_9, [
                                  _createElementVNode("p", {
                                    class: _normalizeClass(["relative text-sm sm:text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer", input.value === true ? 'line-through' : ''])
                                  }, _toDisplayString(input.name), 3),
                                  _createElementVNode("img", {
                                    class: _normalizeClass([input.value === false ? 'block' : 'hidden', "cursor-pointer"]),
                                    src: _imports_0,
                                    alt: ""
                                  }, null, 2),
                                  _createElementVNode("img", {
                                    class: _normalizeClass(input.value === true ? 'block' : 'hidden'),
                                    src: _imports_1,
                                    alt: "Check icon"
                                  }, null, 2)
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (step.inputs.some((input) => input.type === 'image' && input.value?.length === 0))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold" }, " Missing pictures ", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => { return input.type === 'image' }), (input) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: input.id,
                              class: "my-3"
                            }, [
                              _createElementVNode("ul", _hoisted_11, [
                                _createElementVNode("li", _hoisted_12, [
                                  _createElementVNode("p", _hoisted_13, _toDisplayString(input.name), 1),
                                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                                    class: "cursor-pointer",
                                    src: _imports_0,
                                    alt: ""
                                  }, null, -1))
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (step.inputs.some((input) => input.type === 'image' && input.value?.length > 0))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold" }, " Uploaded pictures ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step.inputs.filter((input) => { return input.type === 'image' && input.value.length > 0 }), (input) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: input.id,
                            class: "my-3"
                          }, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", null, [
                                _createElementVNode("h4", _hoisted_16, _toDisplayString(input.name), 1),
                                _createElementVNode("div", _hoisted_17, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(input.value.filter((image) => image.terminId === undefined || selectedTermins.value.includes(image.terminId)), (image) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: image.id,
                                      class: "relative flex-shrink-0"
                                    }, [
                                      _createElementVNode("a", {
                                        href: image.url,
                                        target: "_blank",
                                        class: "group no-underline"
                                      }, [
                                        _createElementVNode("img", {
                                          class: "w-[300px] h-[300px] object-cover rounded-primary-radius",
                                          src: image.url,
                                          alt: "Project image"
                                        }, null, 8, _hoisted_19),
                                        _createElementVNode("div", _hoisted_20, [
                                          _createElementVNode("img", {
                                            class: "w-full max-h-[200px] object-cover rounded-primary-radius",
                                            src: image.url,
                                            alt: "Project image"
                                          }, null, 8, _hoisted_21),
                                          _createElementVNode("div", _hoisted_22, [
                                            _createElementVNode("p", _hoisted_23, _toDisplayString(image.terminName), 1),
                                            _createElementVNode("div", _hoisted_24, [
                                              _cache[7] || (_cache[7] = _createElementVNode("img", {
                                                src: _imports_2,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("p", _hoisted_25, _toDisplayString(image.worker), 1)
                                            ]),
                                            _createElementVNode("div", _hoisted_26, [
                                              _cache[8] || (_cache[8] = _createElementVNode("img", {
                                                class: "w-4 h-4",
                                                src: _imports_3,
                                                alt: ""
                                              }, null, -1)),
                                              _createElementVNode("p", _hoisted_27, _toDisplayString(new Date(
                                        image.createdAt
                                      ).toLocaleDateString("DE")), 1)
                                            ])
                                          ])
                                        ])
                                      ], 8, _hoisted_18)
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["step-id", "step-name"])
        ]))
      }), 128))
    ], 2),
    (!isMap.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _createElementVNode("div", {
            class: _normalizeClass(["xl:absolute xl:top-[24px] xl:right-[24px] flex items-center gap-3 justify-end", isMap.value ? 'hidden' : 'block'])
          }, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("img", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (isTerminFilterOpen.value = !isTerminFilterOpen.value)),
                class: "w-full h-full object-cover",
                src: _imports_4,
                alt: ""
              }),
              (isTerminFilterOpen.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createVNode(TerminFilter, {
                      project: _ctx.phaseDetails,
                      onCloseTerminFilter: _cache[1] || (_cache[1] = ($event: any) => (isTerminFilterOpen.value = false)),
                      onOnFilterSubmit: onFilterSubmit
                    }, null, 8, ["project"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            (props.phaseDetails.project.baummape !== null)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: toggleProjectMap,
                  class: "w-[18px] h-[18px] cursor-pointer"
                }, _cache[10] || (_cache[10] = [
                  _createElementVNode("img", {
                    class: "w-full h-full object-cover",
                    src: _imports_5,
                    alt: ""
                  }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (isMap.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createVNode(ProjectMap, {
            "phase-details": props.phaseDetails,
            onToggleProjectMap: toggleProjectMap
          }, null, 8, ["phase-details"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})