import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/arrow-caret-up.svg'


const _hoisted_1 = { class: "grid justify-items-start 4xl:mr-[152px] gap-[6px] grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 laptop-custom:grid-cols-7 3xl:grid-cols-8 overflow-y-auto" }
const _hoisted_2 = { class: "bg-[#E5E4E2] flex flex-col px-[4.5px] py-[6px] rounded-primary-radius" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "w-6 h-6 flex items-center justify-center bg-white p-2 rounded-primary-radius" }
const _hoisted_5 = { class: "text-xs font-medium text-primary-text-color" }
const _hoisted_6 = { class: "text-xs font-medium text-primary-text-color" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "mt-[6px]"
}

import ProjectCard from "@/components/Card/ProjectCard.vue";
import { Project, Status } from "@/types";
import { textTransform } from "@/utils/parse-data";
import { ref } from "vue";

type Props = {
  statusList: Status[];
  projectList: Project[];
};

type Emits = {
  (event: "toggleStatusOpen", statusId: number): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MainContent',
  props: {
    statusList: {},
    projectList: {}
  },
  emits: ["toggleStatusOpen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const filteredProjectsForStatus = (statusId: number) => {
  return props.projectList.filter(
    (project: any) => project.statusId === statusId
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.statusList.filter((status) => status.isSelected === true), (status) => {
      return (_openBlock(), _createElementBlock("div", {
        key: status.id,
        class: "w-[160px] h-fit"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            onClick: ($event: any) => (emit('toggleStatusOpen', status.id)),
            class: "flex items-center justify-between px-[5px] cursor-pointer"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(filteredProjectsForStatus(status.id).length
                  ? filteredProjectsForStatus(status.id).length
                  : 0), 1)
            ]),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(textTransform)(status.name)), 1),
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: _normalizeClass(["cursor-pointer", status.isOpen ? 'rotate-180' : '0']),
                src: _imports_0,
                alt: ""
              }, null, 2)
            ])
          ], 8, _hoisted_3),
          (status.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectList, (project) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: project.id
                  }, [
                    (project.statusId === status.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(ProjectCard, {
                            "project-id": project.id,
                            customer: project.customer,
                            name: project.name,
                            phases: project.phases,
                            statusId: project.statusId
                          }, null, 8, ["project-id", "customer", "name", "phases", "statusId"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}
}

})