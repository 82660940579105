import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/media-icon.svg'


const _hoisted_1 = { class: "h-[428px] p-3 rounded-primary-radius bg-[#E5E4E2]" }
const _hoisted_2 = { class: "flex flex-col w-full relative overflow-x-auto" }
const _hoisted_3 = { class: "flex flex-col w-full overflow-y-auto h-[366px] relative gap-2 py-3" }
const _hoisted_4 = { class: "flex flex-col gap-[6px]" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = { class: "flex flex-col items-start sm:flex-row sm:items-center gap-1 w-full border-b border-gray-200" }
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text-xs text-[#393840] break-words" }
const _hoisted_10 = {
  key: 0,
  class: "text-xs text-[#393840] border-b border-gray-200 pb-3"
}
const _hoisted_11 = {
  key: 0,
  class: "text-[#898790] text-xs pt-3"
}
const _hoisted_12 = {
  key: 1,
  class: "text-[#898790] text-xs pt-3"
}
const _hoisted_13 = {
  key: 0,
  class: "h-full flex pt-6"
}
const _hoisted_14 = { class: "w-1/5 flex flex-col relative mx-2" }
const _hoisted_15 = { class: "bg-[#393840] text-white py-[4px] px-1 sm:py-[6px] sm:px-2 rounded-primary-radius text-xs text-center z-10 relative mt-4" }
const _hoisted_16 = { class: "flex flex-col gap-[6px] justify-end" }
const _hoisted_17 = {
  key: 0,
  class: "h-full flex pt-6"
}
const _hoisted_18 = { class: "flex flex-col gap-2" }
const _hoisted_19 = { class: "flex items-center gap-1" }
const _hoisted_20 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "text-sm text-[#393840]" }
const _hoisted_23 = {
  key: 0,
  class: "flex text-xs text-[#393840]"
}
const _hoisted_24 = { class: "flex gap" }
const _hoisted_25 = { class: "flex justify-between items-center pt-2" }
const _hoisted_26 = {
  key: 0,
  class: "text-[#898790] text-xs"
}
const _hoisted_27 = {
  key: 1,
  class: "text-[#898790] text-xs"
}

import { Project, ProjectTermin, TerminIssue } from "@/types";
import { formatStatusName, getTerminStatusColor } from "@/utils/status-color";
import { computed } from "vue";

type Props = {
  projectDetails: Project | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminTimeline',
  props: {
    projectDetails: {}
  },
  setup(__props: any) {

const props = __props;

const timelineItems = computed(() => {
  let items: any = [];
  if (props.projectDetails) {
    props.projectDetails.termins.map((item: ProjectTermin) => {
      if (item.start) {
        items.push({
          title: item.name,
          text: "",
          teams: item.teams,
          phase: item.phase,
          date: new Date(item.start),
          side: "left",
        });
      }
      if (item.end) {
        items.push({
          title: item.name,
          text: "",
          teams: item.teams,
          phase: item.phase,
          date: new Date(item.end),
          status: item.status.name,
          side: "right",
        });
      }
    });

    props.projectDetails.issues.map((item: TerminIssue) =>
      items.push({
        title: item.terminName,
        text: item.text,
        teams: [],
        phaseId: item.phaseId,
        phaseName: item.phaseName,
        date: new Date(item.createdAt),
        side: "left",
      })
    );

    items.sort((a: any, b: any) => b.date - a.date);
    items = items.map((item: any) => {
      return {
        ...item,
        date: new Date(
          item.date.getFullYear(),
          item.date.getMonth(),
          item.date.getDate()
        ),
      };
    });
  }
  return items;
});

const dates = computed(() => {
  let dates = [];

  dates = [
    ...new Set(
      timelineItems.value.map((item: any) => {
        const day = String(item.date.getDate()).padStart(2, "0");
        const month = String(item.date.getMonth() + 1).padStart(2, "0");
        const year = item.date.getFullYear();
        return `${day}.${month}.${year}.`;
      })
    ),
  ];
  return dates;
});

const getDateFromString = (dateString: any) => {
  const [day, month, year] = dateString.split(".").map(Number); // Split and convert to numbers
  return new Date(year, month - 1, day);
};

const areDatesSame = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-xl pb-2 text-primary-text-color tracking-[0.01rem] font-semibold" }, " Termin timeline ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "bg-[#898790] mx-auto w-3 h-3 rounded-full" }, null, -1)),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-[2px] h-[366px] bg-[#898790] mx-auto absolute z-10 top-3 left-[50%] ml-[-1px]" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dates.value, (date, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex"
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-2/5 flex flex-col gap-[6px] h-fit", `
          ${timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'left').length > 0 ? 'border-l-2 border-[#85838c] rounded-md' : 'pl-[2px]'}
        `])
            }, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'left'), (card, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "flex"
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["w-full lg:w-[156px] flex flex-col p-[6px] bg-white rounded-primary-radius", index !== 0 ? 'w-[calc(100%-8px)]' : 'w-full'])
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          (!card.text)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                            : _createCommentVNode("", true),
                          (card.text)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
                                _createElementVNode("svg", {
                                  width: "14",
                                  height: "13",
                                  viewBox: "0 0 14 13",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M7 4.429V6.76233M4.27684 1.79687L2.29687 3.77684L2.29508 3.77853C2.0945 3.97911 1.99397 4.07964 1.92204 4.19702C1.85807 4.30141 1.81081 4.41534 1.78224 4.53438C1.75 4.66865 1.75 4.81128 1.75 5.09664V7.90336C1.75 8.18872 1.75 8.33139 1.78224 8.46566C1.81081 8.5847 1.85807 8.69847 1.92204 8.80286C1.99416 8.92055 2.09523 9.02162 2.29687 9.22327L4.27684 11.2032C4.47862 11.405 4.57929 11.5057 4.69702 11.5778C4.80141 11.6418 4.91506 11.6892 5.0341 11.7178C5.16816 11.75 5.31056 11.75 5.59505 11.75H8.40438C8.68887 11.75 8.83187 11.75 8.96594 11.7178C9.08498 11.6892 9.19876 11.6418 9.30314 11.5778C9.42088 11.5057 9.52178 11.405 9.72355 11.2032L11.7035 9.22327C11.9053 9.02149 12.006 8.92059 12.0781 8.80286C12.1421 8.69847 12.1889 8.5847 12.2175 8.46566C12.2498 8.33139 12.25 8.18872 12.25 7.90337V5.09664C12.25 4.81128 12.2498 4.66865 12.2175 4.53438C12.1889 4.41534 12.1421 4.30141 12.0781 4.19703C12.006 4.07929 11.9053 3.97861 11.7035 3.77684L9.72355 1.79687C9.5219 1.59522 9.42084 1.49416 9.30314 1.42204C9.19876 1.35807 9.08498 1.31081 8.96594 1.28224C8.83167 1.25 8.68844 1.25 8.40308 1.25H5.59635C5.311 1.25 5.16837 1.25 5.0341 1.28224C4.91506 1.31081 4.80141 1.35807 4.69702 1.42204C4.58036 1.49353 4.48062 1.59327 4.28248 1.79141L4.27684 1.79687ZM7.02905 8.51233V8.57066L6.97095 8.57078V8.51233H7.02905Z",
                                    stroke: "#ED857F",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ])))
                            : _createCommentVNode("", true),
                          _createElementVNode("h3", _hoisted_9, _toDisplayString(card.title), 1)
                        ]),
                        (card.text)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(card.text && card.text.length > 50
                          ? card.text.slice(0, 50) + "..."
                          : card.text), 1))
                          : _createCommentVNode("", true)
                      ]),
                      (card.phase)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(card.phase), 1))
                        : _createCommentVNode("", true),
                      (card.phaseName)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(card.phaseName), 1))
                        : _createCommentVNode("", true)
                    ], 2),
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[1] || (_cache[1] = [
                          _createElementVNode("svg", {
                            width: "8",
                            height: "16",
                            viewBox: "0 0 8 16",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M7.32785 6.50518C8.22405 7.30076 8.22405 8.69924 7.32785 9.49482L0 16L-8.9018e-07 4.4509e-07L7.32785 6.50518Z",
                              fill: "white"
                            })
                          ], -1)
                        ])))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ], 2),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, _toDisplayString(date), 1)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["w-2/5 flex flex-col gap-[6px] h-fit", `
          ${timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'right').length > 0 ? 'border-r-2 border-[#85838c] rounded-md' : 'pr-[2px]'}
        `])
            }, [
              _createElementVNode("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(timelineItems.value.filter((item)=> areDatesSame(getDateFromString(date), item.date) && item.side === 'right'), (card, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "flex justify-end"
                  }, [
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[2] || (_cache[2] = [
                          _createElementVNode("svg", {
                            width: "8",
                            height: "16",
                            viewBox: "0 0 8 16",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              d: "M0.672147 9.49482C-0.224048 8.69924 -0.224048 7.30076 0.672146 6.50518L8 -4.4509e-07L8 16L0.672147 9.49482Z",
                              fill: "white"
                            })
                          ], -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["w-full lg:w-[156px] flex flex-col p-[6px] bg-white rounded-primary-radius", index !== 0 ? 'w-[calc(100%-8px)]' : 'w-full'])
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          (!card.text)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_20))
                            : _createCommentVNode("", true),
                          (card.text)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[3] || (_cache[3] = [
                                _createElementVNode("svg", {
                                  width: "14",
                                  height: "13",
                                  viewBox: "0 0 14 13",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M7 4.429V6.76233M4.27684 1.79687L2.29687 3.77684L2.29508 3.77853C2.0945 3.97911 1.99397 4.07964 1.92204 4.19702C1.85807 4.30141 1.81081 4.41534 1.78224 4.53438C1.75 4.66865 1.75 4.81128 1.75 5.09664V7.90336C1.75 8.18872 1.75 8.33139 1.78224 8.46566C1.81081 8.5847 1.85807 8.69847 1.92204 8.80286C1.99416 8.92055 2.09523 9.02162 2.29687 9.22327L4.27684 11.2032C4.47862 11.405 4.57929 11.5057 4.69702 11.5778C4.80141 11.6418 4.91506 11.6892 5.0341 11.7178C5.16816 11.75 5.31056 11.75 5.59505 11.75H8.40438C8.68887 11.75 8.83187 11.75 8.96594 11.7178C9.08498 11.6892 9.19876 11.6418 9.30314 11.5778C9.42088 11.5057 9.52178 11.405 9.72355 11.2032L11.7035 9.22327C11.9053 9.02149 12.006 8.92059 12.0781 8.80286C12.1421 8.69847 12.1889 8.5847 12.2175 8.46566C12.2498 8.33139 12.25 8.18872 12.25 7.90337V5.09664C12.25 4.81128 12.2498 4.66865 12.2175 4.53438C12.1889 4.41534 12.1421 4.30141 12.0781 4.19703C12.006 4.07929 11.9053 3.97861 11.7035 3.77684L9.72355 1.79687C9.5219 1.59522 9.42084 1.49416 9.30314 1.42204C9.19876 1.35807 9.08498 1.31081 8.96594 1.28224C8.83167 1.25 8.68844 1.25 8.40308 1.25H5.59635C5.311 1.25 5.16837 1.25 5.0341 1.28224C4.91506 1.31081 4.80141 1.35807 4.69702 1.42204C4.58036 1.49353 4.48062 1.59327 4.28248 1.79141L4.27684 1.79687ZM7.02905 8.51233V8.57066L6.97095 8.57078V8.51233H7.02905Z",
                                    stroke: "#ED857F",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ])))
                            : _createCommentVNode("", true),
                          _createElementVNode("h3", _hoisted_22, _toDisplayString(card.title), 1)
                        ]),
                        (card.teams)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                              _cache[4] || (_cache[4] = _createElementVNode("svg", {
                                width: "14",
                                height: "14",
                                viewBox: "0 0 14 14",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, [
                                _createElementVNode("path", {
                                  d: "M5.25 6.41675C5.25 7.38325 6.0335 8.16675 7 8.16675C7.9665 8.16675 8.75 7.38325 8.75 6.41675C8.75 5.45025 7.9665 4.66675 7 4.66675C6.0335 4.66675 5.25 5.45025 5.25 6.41675Z",
                                  fill: "#D9D9D9"
                                }),
                                _createElementVNode("path", {
                                  d: "M9.91667 11.6667C9.91667 10.7002 8.61083 9.91675 7 9.91675C5.38917 9.91675 4.08333 10.7002 4.08333 11.6667M12.25 9.91697C12.25 9.19935 11.5301 8.58263 10.5 8.31258M1.75 9.91697C1.75 9.19935 2.46989 8.58263 3.5 8.31258M10.5 5.97114C10.858 5.65071 11.0833 5.18504 11.0833 4.66675C11.0833 3.70025 10.2998 2.91675 9.33333 2.91675C8.88513 2.91675 8.47627 3.08525 8.16667 3.36235M3.5 5.97114C3.14198 5.65071 2.91667 5.18504 2.91667 4.66675C2.91667 3.70025 3.70017 2.91675 4.66667 2.91675C5.11487 2.91675 5.52373 3.08525 5.83333 3.36235M7 8.16675C6.0335 8.16675 5.25 7.38325 5.25 6.41675C5.25 5.45025 6.0335 4.66675 7 4.66675C7.9665 4.66675 8.75 5.45025 8.75 6.41675C8.75 7.38325 7.9665 8.16675 7 8.16675Z",
                                  stroke: "#898790",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                })
                              ], -1)),
                              _createElementVNode("div", _hoisted_24, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.teams, (team) => {
                                  return (_openBlock(), _createElementBlock("span", { key: team }, _toDisplayString(team), 1))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("span", {
                          style: _normalizeStyle(`background:${_unref(getTerminStatusColor)(card.status)}`),
                          class: "text-xs font-medium p-1 text-[#393840] rounded-lg"
                        }, _toDisplayString(_unref(formatStatusName)(card.status)), 5),
                        (card.phase)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(card.phase), 1))
                          : _createCommentVNode("", true),
                        (card.phaseName)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(card.phaseName), 1))
                          : _createCommentVNode("", true)
                      ])
                    ], 2)
                  ]))
                }), 128))
              ])
            ], 2)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})