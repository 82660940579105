import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import client from "@/utils/axios";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import AbnahmeView from "./AbnahmeView.vue";
import QcView from "./QcView.vue";
import RegularView from "./RegularView.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PhasePage',
  setup(__props) {

const route = useRoute();

const projectData = ref();
const phaseStatusId = ref();
const isLoading = ref(false);

const selectedPhase = ref();

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

// Fetch data
const getProjectDetails = async () => {
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;
  isLoading.value = true;
  try {
    const response = await client.get(
      `/projects/${projectId}/phase/${phaseId}`
    );
    projectData.value = response.data;

    let tempPhase = response.data.phases.find(
      (item: any) => Number(item.phaseId) === Number(phaseId)
    );

    phaseStatusId.value = tempPhase ? tempPhase.phaseStatus.id : -1;
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => route.params.phaseId, // Watch for the 'id' param change in the route
  (newId, oldId) => {
    if (newId !== oldId) {
      getProjectDetails();
      selectedPhase.value = newId; // Trigger the API call when the route param changes
    }
  },
  { immediate: true } // Immediately run on component mount
);

//

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(MainSidebar, {
            "is-loading": isLoading.value,
            "phase-progress": projectData.value?.phases,
            "phase-steps": projectData.value?.steps,
            "selected-phase": Number(selectedPhase.value),
            onOnPhaseSelect: onPhaseSelect,
            "is-link": true
          }, null, 8, ["is-loading", "phase-progress", "phase-steps", "selected-phase"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          (projectData.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (phaseStatusId.value === 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(AbnahmeView, {
                        "phase-id": Number(_unref(route).params.phaseId),
                        "project-id": _unref(route).params.id + '',
                        "phase-details": projectData.value,
                        onRefreshData: getProjectDetails
                      }, null, 8, ["phase-id", "project-id", "phase-details"])
                    ]))
                  : _createCommentVNode("", true),
                (phaseStatusId.value === 5)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(QcView, {
                        "phase-id": Number(_unref(route).params.phaseId),
                        "project-id": _unref(route).params.id + '',
                        "phase-details": projectData.value,
                        onRefreshData: getProjectDetails
                      }, null, 8, ["phase-id", "project-id", "phase-details"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(RegularView, { "phase-details": projectData.value }, null, 8, ["phase-details"])
                    ]))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})