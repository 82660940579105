<script setup lang="ts">
import { getProjectStatusColor } from "@/utils/status-color";
import { defineProps } from "vue";
import { RouterLink } from "vue-router";

type Props = {
  projectId: number;
  customer: any;
  name: string;
  phases: any;
};

const props = defineProps<Props>();
</script>

<template>
  <!-- Card inner -->
  <RouterLink :to="`/projects/${props.projectId}`">
    <div class="w-full p-2 bg-white rounded-primary-radius">
      <!-- Card content -->
      <div class="break-words">
        <!-- Logo -->
        <img
          v-if="props.customer.logo_url"
          :src="props.customer.logo_url"
          alt="Logo"
        />
        <h2 v-if="!props.customer.logo_url">{{ props.customer.name }}</h2>
        <p class="text-sm font-normal text-primary-text-color pt-[10.63px]">
          {{ props.name }}
        </p>
        <div class="flex items-center gap-1">
          <div
            v-for="(phase, index) in phases"
            :key="index"
            class="w-full flex items-center"
          >
            <span
              :style="`background: ${getProjectStatusColor(
                phase?.status.name
              )}`"
              class="w-full px-[6px] py-[2px] mt-2 text-center text-xs font-medium text-primary-text-color uppercase rounded-primary-radius"
            >
              {{ phase?.phase?.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </RouterLink>
</template>
