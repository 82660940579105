import { defineStore } from "pinia";
import type { User } from "@/types";

export const useUserStore = defineStore("user", {
  state: (): User => ({
    fullName: "",
    email: "",
    lastLogin: "",
    accessToken: "",
    refreshToken: "",
  }),

  getters: {
    getUser: (state) => {
      return {
        fullName: state.fullName,
        email: state.email,
        lastLogin: state.lastLogin,
      };
    },
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
  },

  actions: {
    async setTokens(accessToken: any, refreshToken: any) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    },
    async setUser(name?: string, email?: string) {
      this.fullName = name ? name : "";
      this.email = email ? email : "";
    },
    logout() {
      this.fullName = "";
      this.email = "";
      this.lastLogin = "";
      this.accessToken = "";
      this.refreshToken = "";
    },
  },
  persist: true,
});
