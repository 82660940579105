import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'
import _imports_1 from '@/assets/rounded-selected-checkbox.svg'
import _imports_2 from '@/assets/checkbox-checked.svg'


const _hoisted_1 = {
  key: 0,
  class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-start justify-center py-[88px] overflow-y-auto"
}
const _hoisted_2 = { class: "w-[560px] p-3 mx-3 bg-white rounded-primary-radius" }
const _hoisted_3 = { class: "flex flex-col items-start gap-3 w-full mt-4 mb-3" }
const _hoisted_4 = { class: "w-full flex items-center gap-2" }
const _hoisted_5 = { class: "flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_6 = { class: "flex w-5" }
const _hoisted_7 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_8 = { class: "w-full flex-col flex gap-2" }
const _hoisted_9 = { class: "flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_10 = { class: "flex w-5" }
const _hoisted_11 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_13 = { class: "w-full flex flex-col gap-3" }
const _hoisted_14 = { class: "flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_15 = { class: "flex w-5" }
const _hoisted_16 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_17 = { class: "flex gap-3 items-center" }
const _hoisted_18 = { class: "flex items-center p-2 bg-[#FDE9D5i] border border-[#FCD3AC] rounded-lg gap-3" }
const _hoisted_19 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_20 = {
  key: 1,
  class: "flex flex-col gap-1"
}
const _hoisted_21 = {
  key: 2,
  class: "flex items-center gap-2"
}
const _hoisted_22 = {
  key: 0,
  class: "text-gray-500 italic text-sm flex items-center flex-wrap gap-1"
}
const _hoisted_23 = { class: "flex items-center gap-2 py-2 border-t border-gray-200" }
const _hoisted_24 = {
  for: "report",
  class: "flex items-center gap-2 cursor-pointer"
}
const _hoisted_25 = {
  src: _imports_2,
  alt: ""
}
const _hoisted_26 = { class: "flex items-center justify-end pt-3 border-t border-gray-200" }
const _hoisted_27 = ["disabled"]
const _hoisted_28 = {
  key: 0,
  class: "w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
}
const _hoisted_29 = { key: 1 }

import client from "@/utils/axios";
import { ref } from "vue";
import { toast } from "vue3-toastify";

type Props = {
  isModalOpen: boolean;
  phaseId: number;
  projectId: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "onSubmit"): void;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'QCModal',
  props: {
    isModalOpen: { type: Boolean },
    phaseId: {},
    projectId: {}
  },
  emits: ["toggleModal", "onSubmit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isSelectedCheckbox = ref(false);
const selectedStatus = ref<number>();
const qcFailedDescription = ref<string>();
const missingEquipment = ref<string>();
const files = ref([]);
const collectDataDescription = ref<string>();
const isLoading = ref(false);

const onClose = () => {
  selectedStatus.value = undefined;
  qcFailedDescription.value = "";
  missingEquipment.value = "";
  collectDataDescription.value = "";
  files.value = [];
  emit("toggleModal");
};

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
};

const onSubmit = async () => {
  isLoading.value = true;
  let payload: any = {
    actionId: selectedStatus.value,
  };

  if (payload.actionId) {
    switch (payload.actionId) {
      case 2:
        if (!collectDataDescription.value) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        payload.description = collectDataDescription.value;
        break;
      case 3:
        if (!qcFailedDescription.value) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        payload.description = qcFailedDescription.value;
        if (missingEquipment.value) {
          payload.missingEquipment = missingEquipment.value;
        }
        break;
    }
    const formData = new FormData();
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("actionId", payload.actionId.toString());
    formData.append("description", payload.description + "");
    formData.append("generateReport", isSelectedCheckbox.value.toString());
    formData.append("missingEquipment", missingEquipment.value + "");

    try {
      await client.post(
        `/projects/${props.projectId}/phase/${props.phaseId}/complete-qc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      isLoading.value = false;
      toast.success("QC action saved.");

      if (isSelectedCheckbox.value) {
        client.post(
          `/projects/${props.projectId}/phase/${props.phaseId}/generate-report`
        );
      }

      emit("toggleModal");
      emit("onSubmit");
    } catch (error) {
      console.log(error);
      isLoading.value = false;
    }
  } else {
    isLoading.value = false;
    toast.error("Please select action!");
  }
};

return (_ctx: any,_cache: any) => {
  return (props.isModalOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", { class: "flex items-center justify-between" }, [
            _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }, " QC complete ", -1)),
            _createElementVNode("button", { onClick: onClose }, _cache[7] || (_cache[7] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)
            ]))
          ]),
          _cache[18] || (_cache[18] = _createElementVNode("p", { class: "text-base leading-5 tracking-[0.01rem] font-normal text-[#898790]" }, " Choose an appropriate status ", -1)),
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedStatus).value = $event)),
                    value: 4,
                    type: "radio",
                    class: "rounded-full ml-[3px] scale-150"
                  }, null, 512), [
                    [_vShow, selectedStatus.value !== 4],
                    [_vModelRadio, selectedStatus.value]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
                    [_vShow, selectedStatus.value === 4]
                  ])
                ]),
                _cache[9] || (_cache[9] = _createTextVNode(" QC passed "))
              ])
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedStatus).value = $event)),
                    value: 2,
                    type: "radio",
                    class: "rounded-full ml-[3px] scale-150"
                  }, null, 512), [
                    [_vShow, selectedStatus.value !== 2],
                    [_vModelRadio, selectedStatus.value]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_11, null, 512), [
                    [_vShow, selectedStatus.value === 2]
                  ])
                ]),
                _cache[10] || (_cache[10] = _createTextVNode(" Need to collect more data "))
              ]),
              (selectedStatus.value === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", { class: "text-[#A7A5AE] text-sm" }, "Description", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((collectDataDescription).value = $event)),
                      rows: "5",
                      class: "bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
                    }, null, 512), [
                      [_vModelText, collectDataDescription.value]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("label", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedStatus).value = $event)),
                    value: 3,
                    type: "radio",
                    class: "rounded-full ml-[3px] scale-150"
                  }, null, 512), [
                    [_vShow, selectedStatus.value !== 3],
                    [_vModelRadio, selectedStatus.value]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_16, null, 512), [
                    [_vShow, selectedStatus.value === 3]
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[13] || (_cache[13] = _createElementVNode("span", null, " QC Failed ", -1)),
                  _withDirectives(_createElementVNode("span", _hoisted_18, _cache[12] || (_cache[12] = [
                    _createElementVNode("svg", {
                      width: "20",
                      height: "20",
                      viewBox: "0 0 20 20",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("path", {
                        d: "M10 9V14M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19ZM10.0498 6V6.1L9.9502 6.1002V6H10.0498Z",
                        stroke: "#393840",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ], -1),
                    _createElementVNode("span", null, "You are triggering a Rework", -1)
                  ]), 512), [
                    [_vShow, selectedStatus.value === 3]
                  ])
                ])
              ]),
              (selectedStatus.value === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", { class: "text-[#A7A5AE] text-sm" }, "Description", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((qcFailedDescription).value = $event)),
                      rows: "5",
                      class: "bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
                    }, null, 512), [
                      [_vModelText, qcFailedDescription.value]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (selectedStatus.value === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", { class: "text-[#A7A5AE] text-sm" }, "Missing equipment", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((missingEquipment).value = $event)),
                      rows: "5",
                      class: "bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
                    }, null, 512), [
                      [_vModelText, missingEquipment.value]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (selectedStatus.value === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _cache[16] || (_cache[16] = _createElementVNode("label", {
                      for: "file",
                      class: "px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
                    }, [
                      _createElementVNode("svg", {
                        width: "15",
                        height: "16",
                        viewBox: "0 0 15 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108",
                          stroke: "#393840",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        })
                      ]),
                      _createTextVNode(" Attach Files ")
                    ], -1)),
                    _createElementVNode("input", {
                      multiple: "",
                      class: "hidden",
                      type: "file",
                      id: "file",
                      onChange: handleFileChange
                    }, null, 32),
                    (files.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
                            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(file?.name), 1))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              id: "report",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((isSelectedCheckbox).value = $event)),
              class: _normalizeClass(isSelectedCheckbox.value ? 'hidden' : 'block')
            }, null, 2), [
              [_vModelCheckbox, isSelectedCheckbox.value]
            ]),
            _createElementVNode("label", _hoisted_24, [
              _withDirectives(_createElementVNode("img", _hoisted_25, null, 512), [
                [_vShow, isSelectedCheckbox.value]
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-base font-normal leading-5 tracking-[0.01rem] text-primary-text-color" }, " Generate report ", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("button", {
              disabled: isLoading.value,
              onClick: onSubmit,
              class: "text-base leading-5 tracking-[0.01rem] text-white font-semibold bg-[#393840] rounded-primary-radius outline-none py-3 px-[42px] flex items-center justify-center"
            }, [
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28))
                : (_openBlock(), _createElementBlock("span", _hoisted_29, "Submit"))
            ], 8, _hoisted_27)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})