import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center relative" }
const _hoisted_2 = { class: "flex absolute left-0 top-0" }
const _hoisted_3 = { class: "w-full xl:w-[934px] ml-0 lg:ml-[160px] xl:ml-0" }

import MainLayout from "@/layouts/MainLayout.vue";
import ProjectOverview from "@/components/Project/ProjectOverview.vue";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { onMounted, ref } from "vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import { Project } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDetails',
  setup(__props) {

const selectedPhase = ref();
const isLoading = ref(false);

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

const route = useRoute();
const id = route.params.id;
const projectDetails = ref<Project | null>(null);

// Fetch data
const getProjectDetails = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/projects/${id}`);
    projectDetails.value = response.data;
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
onMounted(() => {
  getProjectDetails();
});
//

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(MainSidebar, {
            "is-loading": isLoading.value,
            "phase-progress": projectDetails.value?.phases,
            "phase-steps": [],
            "selected-phase": selectedPhase.value,
            onOnPhaseSelect: onPhaseSelect,
            "is-link": true
          }, null, 8, ["is-loading", "phase-progress", "selected-phase"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ProjectOverview, { "project-details": projectDetails.value }, null, 8, ["project-details"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})