<script setup lang="ts">
import client from "@/utils/axios";
import { defineEmits, defineProps, ref } from "vue";
import { toast } from "vue3-toastify";

type Props = {
  isModalOpen: boolean;
  phaseId: number;
  projectId: string;
};

const props = defineProps<Props>();

type Emits = {
  (event: "toggleModal"): void;
  (event: "onSubmit"): void;
};
const emit = defineEmits<Emits>();

// const isSelectedCheckbox = ref(false);
const selectedStatus = ref<number>();
const abnahmeFailedDescription = ref<string>();
const collectDataDescription = ref<string>();
const isLoading = ref(false);

const onClose = () => {
  selectedStatus.value = undefined;
  abnahmeFailedDescription.value = "";
  collectDataDescription.value = "";
  emit("toggleModal");
};

const onSubmit = async () => {
  isLoading.value = true;
  let payload = {
    actionId: selectedStatus.value,
    description:
      selectedStatus.value === 2
        ? collectDataDescription.value
        : abnahmeFailedDescription.value,
  };

  if (selectedStatus.value) {
    switch (selectedStatus.value) {
      case 2:
        if (!payload.description) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        break;
      case 6:
        if (!payload.description) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        break;
    }

    try {
      await client.post(
        `/projects/${props.projectId}/phase/${props.phaseId}/complete-abnahme`,
        payload
      );
      isLoading.value = false;
      toast.success("QC action saved.");
      emit("toggleModal");
      emit("onSubmit");
    } catch (error) {
      console.log(error);
      isLoading.value = false;
    }
  } else {
    toast.error("Please select action!");
    isLoading.value = false;
  }
};
</script>

<template>
  <div
    v-if="props.isModalOpen"
    class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-start justify-center pt-[88px]"
  >
    <div class="w-[560px] p-3 mx-3 bg-white rounded-primary-radius">
      <div class="flex items-center justify-between">
        <h4
          class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
        >
          Abnahme complete
        </h4>
        <button @click="onClose">
          <img src="@/assets/close-icon-2.svg" alt="" />
        </button>
      </div>
      <p
        class="text-base leading-5 tracking-[0.01rem] font-normal text-[#898790]"
      >
        Choose an appropriate status
      </p>
      <!-- Status list -->
      <ul class="flex flex-col items-start gap-3 w-full mt-4 mb-3">
        <li class="w-full flex items-center gap-2">
          <label
            class="flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal"
          >
            <div class="flex w-5">
              <input
                v-show="selectedStatus !== 1"
                v-model="selectedStatus"
                :value="1"
                type="radio"
                class="rounded-full ml-[3px] scale-150"
              />
              <img
                v-show="selectedStatus === 1"
                class="cursor-pointer"
                src="@/assets/rounded-selected-checkbox.svg"
                alt=""
              />
            </div>

            Phase is ready for QC
          </label>
        </li>
        <li class="w-full flex-col flex gap-2">
          <label
            class="flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal"
          >
            <div class="flex w-5">
              <input
                v-show="selectedStatus !== 2"
                v-model="selectedStatus"
                :value="2"
                type="radio"
                class="rounded-full ml-[3px] scale-150"
              />
              <img
                v-show="selectedStatus === 2"
                class="cursor-pointer"
                src="@/assets/rounded-selected-checkbox.svg"
                alt=""
              />
            </div>

            Need to collect more data
          </label>
          <div v-if="selectedStatus === 2" class="flex flex-col gap-1">
            <label class="text-[#A7A5AE] text-sm">Description</label>
            <textarea
              v-model="collectDataDescription"
              rows="5"
              class="bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
            ></textarea>
          </div>
        </li>
        <li class="w-full flex flex-col gap-3">
          <label
            class="flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal"
          >
            <div class="flex w-5">
              <input
                v-show="selectedStatus !== 6"
                v-model="selectedStatus"
                :value="6"
                type="radio"
                class="rounded-full ml-[3px] scale-150"
              />
              <img
                v-show="selectedStatus === 6"
                class="cursor-pointer"
                src="@/assets/rounded-selected-checkbox.svg"
                alt=""
              />
            </div>
            <span> Abnahme Failed </span>
          </label>
          <div v-if="selectedStatus === 6" class="flex flex-col gap-1">
            <label class="text-[#A7A5AE] text-sm">Description</label>
            <textarea
              v-model="abnahmeFailedDescription"
              rows="5"
              class="bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
            ></textarea>
          </div>
        </li>
      </ul>
      <div class="flex items-center justify-end pt-3 border-t border-gray-200">
        <button
          @click="onSubmit"
          class="text-base leading-5 tracking-[0.01rem] text-white font-semibold bg-[#393840] rounded-primary-radius outline-none py-3 px-[42px]"
        >
          <div
            :disabled="isLoading"
            v-if="isLoading"
            class="w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
          ></div>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>
