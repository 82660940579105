import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'
import _imports_1 from '@/assets/rounded-selected-checkbox.svg'


const _hoisted_1 = {
  key: 0,
  class: "w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-start justify-center pt-[88px]"
}
const _hoisted_2 = { class: "w-[560px] p-3 mx-3 bg-white rounded-primary-radius" }
const _hoisted_3 = { class: "flex flex-col items-start gap-3 w-full mt-4 mb-3" }
const _hoisted_4 = { class: "w-full flex items-center gap-2" }
const _hoisted_5 = { class: "flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_6 = { class: "flex w-5" }
const _hoisted_7 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_8 = { class: "w-full flex-col flex gap-2" }
const _hoisted_9 = { class: "flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_10 = { class: "flex w-5" }
const _hoisted_11 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_13 = { class: "w-full flex flex-col gap-3" }
const _hoisted_14 = { class: "flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_15 = { class: "flex w-5" }
const _hoisted_16 = {
  class: "cursor-pointer",
  src: _imports_1,
  alt: ""
}
const _hoisted_17 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_18 = { class: "flex items-center justify-end pt-3 border-t border-gray-200" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { key: 1 }

import client from "@/utils/axios";
import { ref } from "vue";
import { toast } from "vue3-toastify";

type Props = {
  isModalOpen: boolean;
  phaseId: number;
  projectId: string;
};

type Emits = {
  (event: "toggleModal"): void;
  (event: "onSubmit"): void;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'AbnahmeModal',
  props: {
    isModalOpen: { type: Boolean },
    phaseId: {},
    projectId: {}
  },
  emits: ["toggleModal", "onSubmit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// const isSelectedCheckbox = ref(false);
const selectedStatus = ref<number>();
const abnahmeFailedDescription = ref<string>();
const collectDataDescription = ref<string>();
const isLoading = ref(false);

const onClose = () => {
  selectedStatus.value = undefined;
  abnahmeFailedDescription.value = "";
  collectDataDescription.value = "";
  emit("toggleModal");
};

const onSubmit = async () => {
  isLoading.value = true;
  let payload = {
    actionId: selectedStatus.value,
    description:
      selectedStatus.value === 2
        ? collectDataDescription.value
        : abnahmeFailedDescription.value,
  };

  if (selectedStatus.value) {
    switch (selectedStatus.value) {
      case 2:
        if (!payload.description) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        break;
      case 6:
        if (!payload.description) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        break;
    }

    try {
      await client.post(
        `/projects/${props.projectId}/phase/${props.phaseId}/complete-abnahme`,
        payload
      );
      isLoading.value = false;
      toast.success("QC action saved.");
      emit("toggleModal");
      emit("onSubmit");
    } catch (error) {
      console.log(error);
      isLoading.value = false;
    }
  } else {
    toast.error("Please select action!");
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (props.isModalOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", { class: "flex items-center justify-between" }, [
            _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold" }, " Abnahme complete ", -1)),
            _createElementVNode("button", { onClick: onClose }, _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)
            ]))
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-base leading-5 tracking-[0.01rem] font-normal text-[#898790]" }, " Choose an appropriate status ", -1)),
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedStatus).value = $event)),
                    value: 1,
                    type: "radio",
                    class: "rounded-full ml-[3px] scale-150"
                  }, null, 512), [
                    [_vShow, selectedStatus.value !== 1],
                    [_vModelRadio, selectedStatus.value]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
                    [_vShow, selectedStatus.value === 1]
                  ])
                ]),
                _cache[7] || (_cache[7] = _createTextVNode(" Phase is ready for QC "))
              ])
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedStatus).value = $event)),
                    value: 2,
                    type: "radio",
                    class: "rounded-full ml-[3px] scale-150"
                  }, null, 512), [
                    [_vShow, selectedStatus.value !== 2],
                    [_vModelRadio, selectedStatus.value]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_11, null, 512), [
                    [_vShow, selectedStatus.value === 2]
                  ])
                ]),
                _cache[8] || (_cache[8] = _createTextVNode(" Need to collect more data "))
              ]),
              (selectedStatus.value === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", { class: "text-[#A7A5AE] text-sm" }, "Description", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((collectDataDescription).value = $event)),
                      rows: "5",
                      class: "bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
                    }, null, 512), [
                      [_vModelText, collectDataDescription.value]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("label", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedStatus).value = $event)),
                    value: 6,
                    type: "radio",
                    class: "rounded-full ml-[3px] scale-150"
                  }, null, 512), [
                    [_vShow, selectedStatus.value !== 6],
                    [_vModelRadio, selectedStatus.value]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_16, null, 512), [
                    [_vShow, selectedStatus.value === 6]
                  ])
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("span", null, " Abnahme Failed ", -1))
              ]),
              (selectedStatus.value === 6)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", { class: "text-[#A7A5AE] text-sm" }, "Description", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((abnahmeFailedDescription).value = $event)),
                      rows: "5",
                      class: "bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
                    }, null, 512), [
                      [_vModelText, abnahmeFailedDescription.value]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("button", {
              onClick: onSubmit,
              class: "text-base leading-5 tracking-[0.01rem] text-white font-semibold bg-[#393840] rounded-primary-radius outline-none py-3 px-[42px]"
            }, [
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    disabled: isLoading.value,
                    class: "w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
                  }, null, 8, _hoisted_19))
                : (_openBlock(), _createElementBlock("span", _hoisted_20, "Submit"))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})