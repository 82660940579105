import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full p-2 bg-white rounded-primary-radius" }
const _hoisted_2 = { class: "break-words" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-sm font-normal text-primary-text-color pt-[10.63px]" }
const _hoisted_6 = { class: "flex items-center gap-1" }

import { getProjectStatusColor } from "@/utils/status-color";

import { RouterLink } from "vue-router";

type Props = {
  projectId: number;
  customer: any;
  name: string;
  phases: any;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectCard',
  props: {
    projectId: {},
    customer: {},
    name: {},
    phases: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RouterLink), {
    to: `/projects/${props.projectId}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (props.customer.logo_url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: props.customer.logo_url,
                alt: "Logo"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (!props.customer.logo_url)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(props.customer.name), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_5, _toDisplayString(props.name), 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phases, (phase, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "w-full flex items-center"
              }, [
                _createElementVNode("span", {
                  style: _normalizeStyle(`background: ${_unref(getProjectStatusColor)(
                phase?.status.name
              )}`),
                  class: "w-full px-[6px] py-[2px] mt-2 text-center text-xs font-medium text-primary-text-color uppercase rounded-primary-radius"
                }, _toDisplayString(phase?.phase?.name), 5)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})