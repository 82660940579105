import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/close-icon-2.svg'
import _imports_1 from '@/assets/checkbox-checked.svg'
import _imports_2 from '@/assets/calendar-icon.svg'
import _imports_3 from '@/assets/users-icon.svg'


const _hoisted_1 = { class: "p-3 bg-white rounded-primary-radius shadow-primary-box-shadow" }
const _hoisted_2 = { class: "flex items-center justify-between mb-[18px]" }
const _hoisted_3 = { class: "w-full p-2 rounded-primary-radius" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["id", "checked", "onChange"]
const _hoisted_6 = ["for"]
const _hoisted_7 = {
  src: _imports_1,
  class: "cursor-pointer"
}
const _hoisted_8 = { class: "text-base pl-2 leading-[26px] tracking-[0.01rem] text-primary-text-color font-medium" }
const _hoisted_9 = { class: "text-xs ml-2 tracking-[0.01rem] text-primary-text-color font-medium py-[2px] px-[6px] rounded-primary-radius bg-[#B1E3FF]" }
const _hoisted_10 = { class: "flex flex-col items-start gap-[6px] mt-[10px]" }
const _hoisted_11 = { class: "px-[40px] flex items-center gap-2" }
const _hoisted_12 = { class: "flex items-center gap-1" }
const _hoisted_13 = { class: "px-[40px] flex items-start gap-2" }
const _hoisted_14 = { class: "flex flex-col" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "flex items-center justify-between pt-3 mt-6 border-t border-gray-200" }

import { ref } from "vue";

type Props = {
  project: any;
};

type Emits = {
  (event: "closeTerminFilter"): void;
  (event: "onFilterSubmit", selectedTermins: Array<string>): void;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TerminFilter',
  props: {
    project: {}
  },
  emits: ["closeTerminFilter", "onFilterSubmit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;

const selectedTermins = ref(props.project.termins.map((item: any) => item.id));
const onTerminSelectChange = (terminId: string) => {
  console.log(terminId);
  if (selectedTermins.value.includes(terminId)) {
    selectedTermins.value = selectedTermins.value.filter(
      (item: string) => item !== terminId
    );
  } else {
    selectedTermins.value.push(terminId);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-lg tracking-[0.01rem] text-primary-text-color font-semibold" }, " Termin filter ", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('closeTerminFilter')))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)
      ]))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.termins, (termin) => {
      return (_openBlock(), _createElementBlock("div", {
        key: termin.id,
        class: _normalizeClass(["w-full flex flex-col gap-3 rounded-primary-radius mb-2", selectedTermins.value.includes(termin.id) ? 'bg-[#DFDEDC]' : 'bg-white'])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              id: 'checkbox-' + termin.id,
              type: "checkbox",
              class: _normalizeClass(selectedTermins.value.includes(termin.id) ? 'hidden' : 'flex'),
              checked: selectedTermins.value.includes(termin.id),
              onChange: ($event: any) => (onTerminSelectChange(termin.id))
            }, null, 42, _hoisted_5),
            _createElementVNode("label", {
              for: 'checkbox-' + termin.id
            }, [
              _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
                [_vShow, selectedTermins.value.includes(termin.id)]
              ])
            ], 8, _hoisted_6),
            _createElementVNode("h5", _hoisted_8, _toDisplayString(termin.name), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(termin.status), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _cache[5] || (_cache[5] = _createElementVNode("img", {
                src: _imports_2,
                alt: ""
              }, null, -1)),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", null, _toDisplayString(new Date(termin.start).toLocaleDateString("DE")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[6] || (_cache[6] = _createElementVNode("img", {
                src: _imports_3,
                alt: ""
              }, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(termin.workers, (worker, index) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "text-sm",
                    key: index
                  }, [
                    _createTextVNode(_toDisplayString(worker ? worker : "-"), 1),
                    (index < termin.workers.length - 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, ", "))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ], 2))
    }), 128)),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedTermins.value = _ctx.project.termins.map((item) => item.id))),
        class: "text-base leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-3 rounded-primary-radius border-2 border-black"
      }, " Reset "),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onFilterSubmit', selectedTermins.value))),
        class: "text-base leading-5 tracking-[0.01rem] text-primary-text-color font-semibold p-3 rounded-primary-radius bg-[#DDDDFB]"
      }, " Save ")
    ])
  ]))
}
}

})