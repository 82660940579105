<script setup lang="ts">
import client from "@/utils/axios";
import { defineEmits, defineProps, ref } from "vue";
import { toast } from "vue3-toastify";

type Props = {
  isModalOpen: boolean;
  phaseId: number;
  projectId: string;
};

const props = defineProps<Props>();

type Emits = {
  (event: "toggleModal"): void;
  (event: "onSubmit"): void;
};
const emit = defineEmits<Emits>();

const isSelectedCheckbox = ref(false);
const selectedStatus = ref<number>();
const qcFailedDescription = ref<string>();
const missingEquipment = ref<string>();
const files = ref([]);
const collectDataDescription = ref<string>();
const isLoading = ref(false);

const onClose = () => {
  selectedStatus.value = undefined;
  qcFailedDescription.value = "";
  missingEquipment.value = "";
  collectDataDescription.value = "";
  files.value = [];
  emit("toggleModal");
};

const handleFileChange = (event: any) => {
  files.value = Array.from(event.target.files);
};

const onSubmit = async () => {
  isLoading.value = true;
  let payload: any = {
    actionId: selectedStatus.value,
  };

  if (payload.actionId) {
    switch (payload.actionId) {
      case 2:
        if (!collectDataDescription.value) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        payload.description = collectDataDescription.value;
        break;
      case 3:
        if (!qcFailedDescription.value) {
          toast.error("Please enter description!");
          isLoading.value = false;
          return;
        }
        payload.description = qcFailedDescription.value;
        if (missingEquipment.value) {
          payload.missingEquipment = missingEquipment.value;
        }
        break;
    }
    const formData = new FormData();
    files.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    formData.append("actionId", payload.actionId.toString());
    formData.append("description", payload.description + "");
    formData.append("generateReport", isSelectedCheckbox.value.toString());
    formData.append("missingEquipment", missingEquipment.value + "");

    try {
      await client.post(
        `/projects/${props.projectId}/phase/${props.phaseId}/complete-qc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      isLoading.value = false;
      toast.success("QC action saved.");

      if (isSelectedCheckbox.value) {
        client.post(
          `/projects/${props.projectId}/phase/${props.phaseId}/generate-report`
        );
      }

      emit("toggleModal");
      emit("onSubmit");
    } catch (error) {
      console.log(error);
      isLoading.value = false;
    }
  } else {
    isLoading.value = false;
    toast.error("Please select action!");
  }
};
</script>

<template>
  <div
    v-if="props.isModalOpen"
    class="w-full h-screen fixed top-0 left-0 right-0 bottom-0 z-50 bg-black/80 flex items-start justify-center py-[88px] overflow-y-auto"
  >
    <div class="w-[560px] p-3 mx-3 bg-white rounded-primary-radius">
      <div class="flex items-center justify-between">
        <h4
          class="text-xl leading-[32px] tracking-[0.01rem] text-primary-text-color font-semibold"
        >
          QC complete
        </h4>
        <button @click="onClose">
          <img src="@/assets/close-icon-2.svg" alt="" />
        </button>
      </div>
      <p
        class="text-base leading-5 tracking-[0.01rem] font-normal text-[#898790]"
      >
        Choose an appropriate status
      </p>
      <!-- Status list -->
      <ul class="flex flex-col items-start gap-3 w-full mt-4 mb-3">
        <li class="w-full flex items-center gap-2">
          <label
            class="flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal"
          >
            <div class="flex w-5">
              <input
                v-show="selectedStatus !== 4"
                v-model="selectedStatus"
                :value="4"
                type="radio"
                class="rounded-full ml-[3px] scale-150"
              />
              <img
                v-show="selectedStatus === 4"
                class="cursor-pointer"
                src="@/assets/rounded-selected-checkbox.svg"
                alt=""
              />
            </div>

            QC passed
          </label>
        </li>
        <li class="w-full flex-col flex gap-2">
          <label
            class="flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal"
          >
            <div class="flex w-5">
              <input
                v-show="selectedStatus !== 2"
                v-model="selectedStatus"
                :value="2"
                type="radio"
                class="rounded-full ml-[3px] scale-150"
              />
              <img
                v-show="selectedStatus === 2"
                class="cursor-pointer"
                src="@/assets/rounded-selected-checkbox.svg"
                alt=""
              />
            </div>

            Need to collect more data
          </label>
          <div v-if="selectedStatus === 2" class="flex flex-col gap-1">
            <label class="text-[#A7A5AE] text-sm">Description</label>
            <textarea
              v-model="collectDataDescription"
              rows="5"
              class="bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
            ></textarea>
          </div>
        </li>
        <li class="w-full flex flex-col gap-3">
          <label
            class="flex items-center gap-2 cursor-pointer text-base leading-5 tracking-[0.01rem] text-primary-text-color font-normal"
          >
            <div class="flex w-5">
              <input
                v-show="selectedStatus !== 3"
                v-model="selectedStatus"
                :value="3"
                type="radio"
                class="rounded-full ml-[3px] scale-150"
              />
              <img
                v-show="selectedStatus === 3"
                class="cursor-pointer"
                src="@/assets/rounded-selected-checkbox.svg"
                alt=""
              />
            </div>
            <div class="flex gap-3 items-center">
              <span> QC Failed </span>
              <span
                v-show="selectedStatus === 3"
                class="flex items-center p-2 bg-[#FDE9D5i] border border-[#FCD3AC] rounded-lg gap-3"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 9V14M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19ZM10.0498 6V6.1L9.9502 6.1002V6H10.0498Z"
                    stroke="#393840"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>You are triggering a Rework</span>
              </span>
            </div>
          </label>
          <div v-if="selectedStatus === 3" class="flex flex-col gap-1">
            <label class="text-[#A7A5AE] text-sm">Description</label>
            <textarea
              v-model="qcFailedDescription"
              rows="5"
              class="bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
            ></textarea>
          </div>
          <div v-if="selectedStatus === 3" class="flex flex-col gap-1">
            <label class="text-[#A7A5AE] text-sm">Missing equipment</label>
            <textarea
              v-model="missingEquipment"
              rows="5"
              class="bg-[#F9F8F6] border-[#393840] border-2 rounded-lg p-2"
            ></textarea>
          </div>
          <div v-if="selectedStatus === 3" class="flex items-center gap-2">
            <label
              for="file"
              class="px-4 py-2 text-[#393840] font-medium rounded-md cursor-pointer transition flex items-center border-2 border-[#393840] w-fit hover:bg-[#F9F8F6]"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.40186 7.59852L6.57257 2.4278C8.11026 0.890114 10.6034 0.890114 12.1411 2.4278C13.6788 3.96549 13.6786 6.45872 12.1409 7.99641L6.17468 13.9626C5.14956 14.9877 3.48778 14.9876 2.46265 13.9625C1.43753 12.9373 1.43728 11.2755 2.4624 10.2503L8.42862 4.28413C8.94118 3.77156 9.77265 3.77156 10.2852 4.28413C10.7978 4.79669 10.7974 5.62752 10.2849 6.14008L5.11414 11.3108"
                  stroke="#393840"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Attach Files
            </label>
            <input
              multiple
              class="hidden"
              type="file"
              id="file"
              @change="handleFileChange"
            />
            <span
              v-if="files"
              class="text-gray-500 italic text-sm flex items-center flex-wrap gap-1"
            >
              <span v-for="(file, index) in files" :key="index">{{
                file?.name
              }}</span>
            </span>
          </div>
        </li>
      </ul>
      <div class="flex items-center gap-2 py-2 border-t border-gray-200">
        <input
          type="checkbox"
          id="report"
          v-model="isSelectedCheckbox"
          :class="isSelectedCheckbox ? 'hidden' : 'block'"
        />
        <label for="report" class="flex items-center gap-2 cursor-pointer">
          <img
            v-show="isSelectedCheckbox"
            src="@/assets/checkbox-checked.svg"
            alt=""
          />
          <p
            class="text-base font-normal leading-5 tracking-[0.01rem] text-primary-text-color"
          >
            Generate report
          </p>
        </label>
      </div>
      <!-- Submit button -->
      <div class="flex items-center justify-end pt-3 border-t border-gray-200">
        <button
          :disabled="isLoading"
          @click="onSubmit"
          class="text-base leading-5 tracking-[0.01rem] text-white font-semibold bg-[#393840] rounded-primary-radius outline-none py-3 px-[42px] flex items-center justify-center"
        >
          <div
            v-if="isLoading"
            class="w-8 h-8 border-4 border-white border-t-transparent rounded-full animate-spin"
          ></div>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>
