import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/download-icon.svg'


const _hoisted_1 = { class: "w-full h-[428px] p-3 bg-white rounded-primary-radius" }
const _hoisted_2 = { class: "w-full h-[366px] flex flex-col items-start gap-2 overflow-y-auto" }
const _hoisted_3 = { class: "p-2 rounded-primary-radius border border-gray-300" }
const _hoisted_4 = { class: "text-base leading-[20px] text-[#898790] font-semibold tracking-[0.01rem] pb-1" }
const _hoisted_5 = { class: "text-base text-primary-text-color font-normal tracking-[0.01rem]" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "p-2 rounded-primary-radius border border-gray-300 w-full"
}
const _hoisted_8 = { class: "text-base text-primary-text-color font-normal tracking-[0.01rem]" }
const _hoisted_9 = ["href"]

import { Project } from "@/types";
import { textTransform } from "@/utils/parse-document";


type Props = {
  projectDetails: Project | null;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDocuments',
  props: {
    projectDetails: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center justify-between" }, [
      _createElementVNode("h2", { class: "text-xl pb-2 text-primary-text-color tracking-[0.01rem] font-semibold" }, " Documents ")
    ], -1)),
    _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "text-base leading-[26px] text-primary-text-color font-medium pb-1" }, " Booking info ", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectDetails?.documentTypes, (type) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["w-full flex flex-col gap-2", `${_ctx.projectDetails && _ctx.projectDetails?.documents.filter((item)=> item.project_document_type && item.project_document_type.id === type.id)?.length > 0 ? 'block':'hidden'}`]),
          key: type.id
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_unref(textTransform)(type.name)), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectDetails?.documents.filter((item)=> item.project_document_type && item.project_document_type.id === type.id), (document, index) => {
              return (_openBlock(), _createElementBlock("ul", {
                key: document.id,
                class: "list-none flex flex-col gap-[6px]"
              }, [
                _createElementVNode("li", {
                  class: _normalizeClass(`flex items-center justify-between ${
                index !== 0 ? 'border-t border-[#E3E1EA]' : ''
              }`)
                }, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(document.name), 1),
                  _createElementVNode("a", {
                    href: document.url,
                    target: "_blank",
                    download: ""
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      class: "w-[10px] h-[14px]",
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ]), 8, _hoisted_6)
                ], 2)
              ]))
            }), 128))
          ])
        ], 2))
      }), 128)),
      (_ctx.projectDetails && _ctx.projectDetails?.documents.filter((item)=> !item.project_document_type).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "text-base leading-[20px] text-[#898790] font-semibold tracking-[0.01rem] pb-1" }, " Other ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectDetails?.documents.filter((item)=> !item.project_document_type), (document, index) => {
              return (_openBlock(), _createElementBlock("ul", {
                key: document.id,
                class: "list-none flex flex-col gap-[6px] w-full"
              }, [
                _createElementVNode("li", {
                  class: _normalizeClass(`flex items-center justify-between ${
              index !== 0 ? 'border-t border-[#E3E1EA]' : ''
            }`)
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(document.name), 1),
                  _createElementVNode("a", {
                    href: document.url,
                    target: "_blank",
                    download: ""
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("img", {
                      class: "w-[10px] h-[14px]",
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ]), 8, _hoisted_9)
                ], 2)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})