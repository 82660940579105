<script setup lang="ts">
import CustomAccordion from "@/components/Re-usable/CustomAccordion.vue";
import { ref, defineProps } from "vue";
import TechnicalDetails from "@/components/Project/TechnicalDetails.vue";
import TerminFilter from "@/components/Re-usable/TerminFilter.vue";
import ProjectMap from "@/components/Project/ProjectMap.vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";

type Props = {
  phaseDetails: any;
};

const route = useRoute();

const props = defineProps<Props>();
console.log(props.phaseDetails);

const isTerminFilterOpen = ref(false);
const isMap = ref(false);
const isLoading = ref(false);

const generateReport = async () => {
  isLoading.value = true;
  const projectId = route.params.id;
  const phaseId = route.params.phaseId;

  let resp = await client.post(
    `/projects/${projectId}/phase/${phaseId}/generate-report`
  );
  if (resp && resp.status === 200) {
    toast.success("Report succesfully generated.");
  } else {
    toast.error("Something went wrong!");
  }
  isLoading.value = false;
};

const toggleProjectMap = () => {
  isMap.value = !isMap.value;
};

const selectedTermins = ref(
  props.phaseDetails.termins.map((item: any) => item.id)
);

const onFilterSubmit = (termins: Array<string>) => {
  selectedTermins.value = [...termins];
};
</script>

<template>
  <div
    class="laptop-custom:-ml-[88px] w-full h-screen flex items-start gap-4 overflow-y-auto"
    :class="isMap ? 'justify-self-end' : 'justify-self-start'"
  >
    <div
      class="h-[calc(100vh-24px)] lg:h-[calc(100vh-46px)] overflow-y-auto"
      :class="`${
        isMap
          ? 'w-[60%]'
          : 'max-w-[700px] xl:max-w-[800px] laptop-custom:max-w-[934px] mx-auto'
      }`"
    >
      <!-- Header -->
      <div class="flex flex-col w-full sticky top-0 z-20 bg-[#efeeec]">
        <div
          class="flex flex-col md:flex-row items-start md:items-center justify-between mb-6"
        >
          <h2
            class="text-lg pb-3 md:pb-0 sm:text-[32px] text-primary-text-color leading-[41.5xp] tracking-[0.01rem] font-semibold"
          >
            {{ phaseDetails.project.name }}
          </h2>
          <button
            :disabled="isLoading"
            @click="generateReport"
            class="flex items-center gap-1 text-base text-white font-semibold leading-5 tracking-[0.01rem] p-3 bg-[#393840] rounded-primary-radius outline-none hover:bg-[#202022] duration-300"
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="`${isLoading ? 'animate-spin' : ''}`"
            >
              <path
                d="M5.50024 11H1.75024V14.75M8.50024 5H12.2502V1.25M1.4375 5.75255C1.85801 4.71175 2.56207 3.80978 3.46966 3.14917C4.37724 2.48856 5.45289 2.09564 6.57256 2.01538C7.69223 1.93512 8.81129 2.17055 9.80382 2.69496C10.7963 3.21937 11.6212 4.0119 12.1858 4.98209M12.5634 10.2478C12.1429 11.2886 11.4388 12.1906 10.5312 12.8512C9.62367 13.5118 8.54912 13.9042 7.42944 13.9845C6.30977 14.0647 5.18979 13.8293 4.19727 13.3049C3.20474 12.7805 2.37936 11.9881 1.8147 11.0179"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Generate report
          </button>
        </div>
        <div>
          <TechnicalDetails :project="phaseDetails.project" />
        </div>
      </div>
      <!-- Main content -->
      <div v-for="step in phaseDetails.steps" :key="step.id" class="mt-[36px]">
        <CustomAccordion :step-id="step.id" :step-name="step.name">
          <div>
            <div class="flex flex-col gap-3">
              <div class="flex gap-3">
                <!-- Type - checkbox -->
                <div
                  v-if="step.inputs.some((input: any) => input.type === 'checkbox')"
                  class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                >
                  <h3
                    class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
                  >
                    Checklist
                  </h3>
                  <div
                    v-for="input in step.inputs.filter((input: any) => { return input.type === 'checkbox' && (input.terminId === undefined || selectedTermins.includes(input.terminId)) })"
                    :key="input.id"
                    class="my-3"
                  >
                    <ul
                      class="w-full list-none flex flex-col items-start gap-3"
                    >
                      <li
                        class="w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1"
                      >
                        <p
                          class="relative text-sm sm:text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer"
                          :class="input.value === true ? 'line-through' : ''"
                        >
                          {{ input.name }}
                        </p>
                        <img
                          :class="input.value === false ? 'block' : 'hidden'"
                          class="cursor-pointer"
                          src="@/assets/red-x-mark.svg"
                          alt=""
                        />
                        <img
                          :class="input.value === true ? 'block' : 'hidden'"
                          src="@/assets/accordion-check-icon.svg"
                          alt="Check icon"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- Type - missing image -->
                <div
                  v-if="step.inputs.some((input: any) => input.type === 'image' && input.value?.length === 0)"
                  class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                >
                  <h3
                    class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
                  >
                    Missing pictures
                  </h3>
                  <div
                    v-for="input in step.inputs.filter((input: any) => { return input.type === 'image' })"
                    :key="input.id"
                    class="my-3"
                  >
                    <ul
                      class="w-full list-none flex flex-col items-start gap-3"
                    >
                      <li
                        class="w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1"
                      >
                        <p
                          class="relative text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer"
                        >
                          {{ input.name }}
                        </p>
                        <img
                          class="cursor-pointer"
                          src="@/assets/red-x-mark.svg"
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Type - image -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'image' && input.value?.length > 0)"
                class="col-span-2 p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
              >
                <h3
                  class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
                >
                  Uploaded pictures
                </h3>
                <div
                  v-for="input in step.inputs.filter((input: any) => { return input.type === 'image' && input.value.length > 0 })"
                  :key="input.id"
                  class="my-3"
                >
                  <!-- Image inner -->
                  <div class="flex flex-col items-start gap-2 overflow-x-auto">
                    <!-- Image block row -->
                    <div>
                      <h4
                        class="text-base leading-5 font-normal tracking-[0.01rem] text-primary-text-color pb-2"
                      >
                        {{ input.name }}
                      </h4>
                      <div
                        class="max-w-[948px] overflow-x-auto overflow-y-auto flex items-center gap-3"
                      >
                        <div
                          v-for="image in input.value.filter((image:any) => image.terminId === undefined || selectedTermins.includes(image.terminId))"
                          :key="image.id"
                          class="relative flex-shrink-0"
                        >
                          <a
                            :href="image.url"
                            target="_blank"
                            class="group no-underline"
                          >
                            <img
                              class="w-[300px] h-[300px] object-cover rounded-primary-radius"
                              :src="image.url"
                              alt="Project image"
                            />
                            <div
                              class="group-hover:block hidden absolute max-w-[300px] max-h-[300px] inset-0 bg-[#efeeec] p-3 rounded-primary-radius opacity-0 group-hover:opacity-100 cursor-pointer overflow-hidden z-10"
                            >
                              <img
                                class="w-full max-h-[200px] object-cover rounded-primary-radius"
                                :src="image.url"
                                alt="Project image"
                              />
                              <div class="mt-2 flex flex-col items-start gap-1">
                                <p
                                  class="text-sm text-primary-text-color font-normal"
                                >
                                  {{ image.terminName }}
                                </p>
                                <div class="flex items-center gap-1">
                                  <img
                                    src="@/assets/image-user-hover.svg"
                                    alt=""
                                  />
                                  <p
                                    class="text-sm text-primary-text-color font-normal"
                                  >
                                    {{ image.worker }}
                                  </p>
                                </div>
                                <!-- Date -->
                                <div class="flex items-center gap-1">
                                  <img
                                    class="w-4 h-4"
                                    src="@/assets/calendar-icon.svg"
                                    alt=""
                                  />
                                  <p
                                    class="text-sm text-primary-text-color font-normal"
                                  >
                                    {{
                                      new Date(
                                        image.createdAt
                                      ).toLocaleDateString("DE")
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomAccordion>
      </div>
    </div>
    <!-- Right block -->
    <div v-if="!isMap">
      <div
        class="xl:absolute xl:top-[24px] xl:right-[24px] flex items-center gap-3 justify-end"
        :class="isMap ? 'hidden' : 'block'"
      >
        <div class="relative w-[18px] h-[14px] cursor-pointer">
          <img
            @click="isTerminFilterOpen = !isTerminFilterOpen"
            class="w-full h-full object-cover"
            src="@/assets/ac-header-icon-1.svg"
            alt=""
          />
          <div
            v-if="isTerminFilterOpen"
            class="w-[310px] absolute top-6 right-0 z-[888]"
          >
            <TerminFilter
              :project="phaseDetails"
              @close-termin-filter="isTerminFilterOpen = false"
              @on-filter-submit="onFilterSubmit"
            />
          </div>
        </div>
        <div
          v-if="props.phaseDetails.project.baummape !== null"
          @click="toggleProjectMap"
          class="w-[18px] h-[18px] cursor-pointer"
        >
          <img
            class="w-full h-full object-cover"
            src="@/assets/ac-header-icon-2.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div v-if="isMap" class="flex-1 min-h-screen w-[40%]">
      <ProjectMap
        :phase-details="props.phaseDetails"
        @toggle-project-map="toggleProjectMap"
      />
    </div>
  </div>
</template>
