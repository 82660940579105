import router from "@/router";
import { useUserStore } from "@/stores/user";
import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT + "/api/qc",
  headers: { "Content-Type": "application/json" },
});

// let isRefreshing = false;
// let failedRequests: any = [];

// const processFailedRequests = (error: any, token = null) => {
//   failedRequests.forEach((promise: any) => {
//     if (token) {
//       promise.resolve(token);
//     } else {
//       promise.reject(error);
//     }
//   });
//   failedRequests = [];
// };

// Request interceptor
client.interceptors.request.use(
  (config: any) => {
    const userStore = useUserStore();
    if (config.headers) {
      config.headers["x-supabase-auth"] = `${userStore.getAccessToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    const userStore = useUserStore();

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error && error.response && error.response.status === 401) {
      userStore.logout();
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default client;
