<script setup lang="ts">
import ProjectCard from "@/components/Card/ProjectCard.vue";
import { Project, Status } from "@/types";
import { textTransform } from "@/utils/parse-data";
import { defineEmits, defineProps, ref } from "vue";

type Props = {
  statusList: Status[];
  projectList: Project[];
};

const props = defineProps<Props>();

type Emits = {
  (event: "toggleStatusOpen", statusId: number): void;
};

const emit = defineEmits<Emits>();

const filteredProjectsForStatus = (statusId: number) => {
  return props.projectList.filter(
    (project: any) => project.statusId === statusId
  );
};
</script>

<template>
  <div
    class="grid justify-items-start 4xl:mr-[152px] gap-[6px] grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 laptop-custom:grid-cols-7 3xl:grid-cols-8 overflow-y-auto"
  >
    <!-- Project column -->
    <div
      v-for="status in props.statusList.filter((status: any) => status.isSelected === true)"
      :key="status.id"
      class="w-[160px] h-fit"
    >
      <div
        class="bg-[#E5E4E2] flex flex-col px-[4.5px] py-[6px] rounded-primary-radius"
      >
        <!-- Column header -->
        <div
          @click="emit('toggleStatusOpen', status.id)"
          class="flex items-center justify-between px-[5px] cursor-pointer"
        >
          <div
            class="w-6 h-6 flex items-center justify-center bg-white p-2 rounded-primary-radius"
          >
            <span class="text-xs font-medium text-primary-text-color">
              {{
                filteredProjectsForStatus(status.id).length
                  ? filteredProjectsForStatus(status.id).length
                  : 0
              }}
            </span>
          </div>
          <span class="text-xs font-medium text-primary-text-color">{{
            textTransform(status.name)
          }}</span>
          <div>
            <img
              class="cursor-pointer"
              :class="status.isOpen ? 'rotate-180' : '0'"
              src="@/assets/arrow-caret-up.svg"
              alt=""
            />
          </div>
        </div>

        <div v-if="status.isOpen">
          <!-- Project card -->
          <div v-for="project in projectList" :key="project.id">
            <div v-if="project.statusId === status.id" class="mt-[6px]">
              <ProjectCard
                :project-id="project.id"
                :customer="project.customer"
                :name="project.name"
                :phases="project.phases"
                :statusId="project.statusId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
