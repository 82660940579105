import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/project-details-three-card-image-1.svg'
import _imports_1 from '@/assets/code-brackets.svg'
import _imports_2 from '@/assets/edit-copy.svg'
import _imports_3 from '@/assets/calendar-frame.svg'


const _hoisted_1 = { class: "my-3 grid grid-cols-1 md:grid-cols-3 gap-[12.5px]" }
const _hoisted_2 = { class: "flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_3 = { class: "text-lg leading-[22.2px] tracking-[0.01rem] text-primary-text-color font-normal" }
const _hoisted_4 = { class: "flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]" }
const _hoisted_7 = { class: "flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]" }
const _hoisted_8 = { class: "flex items-center justify-between" }
const _hoisted_9 = { class: "text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]" }
const _hoisted_10 = { class: "text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]" }



type Props = {
  customerName: string;
  meister: string;
  costCarrierCode: string;
  nextTermin: any;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ThreeBlockRow',
  props: {
    customerName: {},
    meister: {},
    costCarrierCode: {},
    nextTermin: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex items-center gap-2" }, [
        _createElementVNode("div", { class: "flex items-center justify-center" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ]),
        _createElementVNode("h3", { class: "text-lg text-primary-text-color font-semibold" }, " Responsible Meister ")
      ], -1)),
      _createElementVNode("h4", _hoisted_3, _toDisplayString(props.meister ? props.meister : ""), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-center gap-2" }, [
        _createElementVNode("div", { class: "flex items-center justify-center" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          })
        ]),
        _createElementVNode("h3", { class: "text-lg text-primary-text-color font-semibold" }, " Cost Carrier Code ")
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(props.costCarrierCode ? props.costCarrierCode : "") + ".", 1),
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "cursor-pointer",
          src: _imports_2,
          alt: ""
        }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center gap-2" }, [
        _createElementVNode("div", { class: "flex items-center justify-center" }, [
          _createElementVNode("img", {
            src: _imports_3,
            alt: ""
          })
        ]),
        _createElementVNode("h3", { class: "text-lg text-primary-text-color font-semibold" }, " Next appointment ")
      ], -1)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(props.nextTermin
              ? new Date(props.nextTermin?.start).toLocaleDateString("DE")
              : "-"), 1),
        _createElementVNode("span", _hoisted_10, _toDisplayString(props.nextTermin ? props.nextTermin?.name : ""), 1)
      ])
    ])
  ]))
}
}

})