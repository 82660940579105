<script setup lang="ts">
import CustomAccordion from "@/components/Re-usable/CustomAccordion.vue";
import { ref, defineProps, defineEmits } from "vue";
import TechnicalDetails from "@/components/Project/TechnicalDetails.vue";
import TerminFilter from "@/components/Re-usable/TerminFilter.vue";
import ProjectMap from "@/components/Project/ProjectMap.vue";
import AbnahmeModal from "@/components/PhaseDetails/AbnahmeModal.vue";

type Props = {
  phaseDetails: any;
  phaseId: number;
  projectId: string;
};

const props = defineProps<Props>();

type Emits = {
  (event: "refreshData"): void;
};
defineEmits<Emits>();

const isModal = ref(false);
const isTerminFilterOpen = ref(false);
const isMap = ref(false);
const isAccordion = ref(true);

const toggleModalHandler = () => {
  isModal.value = !isModal.value;
};

const selectedTermins = ref(
  props.phaseDetails.termins.map((item: any) => item.id)
);

const onFilterSubmit = (termins: Array<string>) => {
  selectedTermins.value = [...termins];
};

const toggleProjectMap = () => {
  isMap.value = !isMap.value;
};
</script>

<template>
  <div
    class="laptop-custom:-ml-[88px] w-full h-screen flex flex-col md:flex-row items-start gap-3 overflow-y-auto"
  >
    <div
      class="h-[calc(100vh-24px)] lg:h-[calc(100vh-46px)] max-w-[700px] xl:max-w-[800px] laptop-custom:max-w-[934px] mx-auto overflow-y-auto"
    >
      <!-- Header -->
      <div class="flex flex-col w-full sticky top-0 z-20 bg-[#efeeec]">
        <div
          class="flex flex-col md:flex-row items-start md:items-center justify-between mb-6"
        >
          <h2
            class="text-lg pb-3 md:pb-0 sm:text-[32px] text-primary-text-color leading-[41.5xp] tracking-[0.01rem] font-semibold"
          >
            {{ phaseDetails.project.name }}
          </h2>
          <button
            @click="toggleModalHandler"
            class="text-base text-white font-semibold leading-5 tracking-[0.01rem] p-3 bg-[#393840] rounded-primary-radius outline-none hover:bg-[#202022] duration-300"
          >
            Abnahme complete
          </button>
        </div>
        <div v-if="isModal">
          <AbnahmeModal
            :phase-id="phaseId"
            :project-id="projectId"
            :is-modal-open="isModal"
            @toggle-modal="toggleModalHandler"
            @on-submit="$emit('refreshData')"
          />
        </div>
        <div>
          <TechnicalDetails :project="phaseDetails.project" />
        </div>
      </div>
      <!-- Main content -->
      <div v-for="step in phaseDetails.steps" :key="step.id" class="mt-[36px]">
        <CustomAccordion
          :is-open="isAccordion"
          :step-id="step.id"
          :step-name="step.name"
        >
          <div class="flex flex-col gap-3">
            <div class="flex gap-3">
              <!-- Type - checkbox -->
              <div
                v-if="step.inputs.some((input: any) => input.type === 'checkbox' && input.value === false)"
                class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
              >
                <h3
                  class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
                >
                  Checklist
                </h3>
                <div
                  v-for="input in step.inputs.filter((input: any) => { return input.type === 'checkbox' && input.value === false})"
                  :key="input.id"
                  class="my-3"
                >
                  <ul class="w-full list-none flex flex-col items-start gap-3">
                    <li
                      class="w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1"
                    >
                      <p
                        class="relative text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer"
                      >
                        {{ input.name }}
                      </p>
                      <img
                        class="cursor-pointer"
                        src="@/assets/red-x-mark.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Type - missing image -->
              <div
                class="p-3 w-full bg-white rounded-primary-radius shadow-primary-box-shadow"
                v-if="step.inputs.some((input: any) => input.type === 'image'&& input.value?.length === 0)"
              >
                <h3
                  class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
                >
                  Missing pictures
                </h3>
                <div
                  v-for="input in step.inputs.filter((input: any) => { return input.type === 'image' && input.value?.length === 0})"
                  :key="input.id"
                  class="my-3"
                >
                  <ul class="w-full list-none flex flex-col items-start gap-3">
                    <li
                      class="w-full flex items-center gap-2 justify-between border-b border-gray-100 pb-1"
                    >
                      <p
                        class="relative text-sm sm:text-base text-primary-text-color font-normal leading-5 tracking-[0.01rem] cursor-pointer"
                      >
                        {{ input.name }}
                        <span
                          class="absolute bg-primary-text-color w-full h-[1px] top-1/2 left-0"
                        ></span>
                      </p>
                      <img
                        class="cursor-pointer"
                        src="@/assets/red-x-mark.svg"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Type - image -->
            <div
              v-if="step.inputs.some((input: any) => input.type === 'image' && input.value?.length > 0)"
              class="col-span-2 p-3 bg-white rounded-primary-radius shadow-primary-box-shadow"
            >
              <h3
                class="text-xl leading-[32px] tracking-[0.01rem] pb-3 text-primary-text-color font-semibold"
              >
                Uploaded pictures
              </h3>
              <div
                v-for="input in step.inputs.filter((input: any) => { return input.type === 'image' && input.value?.length > 0})"
                :key="input.id"
              >
                <!-- Image inner -->
                <div class="flex flex-col items-start gap-2">
                  <!-- Image block row -->
                  <div>
                    <h4
                      class="text-base leading-5 font-normal tracking-[0.01rem] text-primary-text-color pb-2"
                    >
                      {{ input.name }}
                    </h4>
                    <div
                      class="max-w-[948px] overflow-x-auto overflow-y-auto flex items-center gap-3"
                    >
                      <div
                        v-for="image in input.value.filter((image:any)=> image.terminId === undefined || selectedTermins.includes(image.terminId))"
                        :key="image.id"
                        class="flex-shrink-0 relative"
                      >
                        <a
                          :href="image.url"
                          target="_blank"
                          class="group no-underline"
                        >
                          <img
                            class="w-[300px] h-[300px] object-cover rounded-primary-radius"
                            :src="image.url"
                            alt="Project image"
                          />
                          <div
                            class="group-hover:block hidden absolute max-w-[300px] max-h-[300px] inset-0 bg-[#efeeec] p-3 rounded-primary-radius opacity-0 group-hover:opacity-100 cursor-pointer overflow-hidden"
                          >
                            <img
                              class="w-full h-[200px] object-cover rounded-primary-radius"
                              :src="image.url"
                              alt="Project image"
                            />
                            <div class="mt-2 flex flex-col items-start gap-1">
                              <p
                                class="text-sm text-primary-text-color font-normal"
                              >
                                {{ image.terminName }}
                              </p>
                              <div class="flex items-center gap-1">
                                <img
                                  src="@/assets/image-user-hover.svg"
                                  alt=""
                                />
                                <p
                                  class="text-sm text-primary-text-color font-normal"
                                >
                                  {{ image.worker }}
                                </p>
                              </div>
                              <!-- Date -->
                              <div class="flex items-center gap-1">
                                <img
                                  class="w-4 h-4"
                                  src="@/assets/calendar-icon.svg"
                                  alt=""
                                />
                                <p
                                  class="text-sm text-primary-text-color font-normal"
                                >
                                  {{
                                    new Date(
                                      image.createdAt
                                    ).toLocaleDateString("DE")
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomAccordion>
      </div>
    </div>
    <!-- Right block -->
    <div>
      <div
        class="xl:absolute xl:top-[24px] xl:right-[24px] flex items-center gap-3 justify-end"
        :class="isMap ? 'hidden' : 'block'"
      >
        <div class="relative w-[18px] h-[14px] cursor-pointer">
          <img
            @click="isTerminFilterOpen = !isTerminFilterOpen"
            class="w-full h-full object-cover"
            src="@/assets/ac-header-icon-1.svg"
            alt=""
          />
          <div
            v-if="isTerminFilterOpen"
            class="w-[310px] absolute top-6 right-0 z-[888]"
          >
            <TerminFilter
              :project="phaseDetails"
              @close-termin-filter="isTerminFilterOpen = false"
              @on-filter-submit="onFilterSubmit"
            />
          </div>
        </div>
        <div
          v-if="props.phaseDetails.project.baummape !== null"
          @click="toggleProjectMap"
          class="w-[18px] h-[18px] cursor-pointer"
        >
          <img
            class="w-full h-full object-cover"
            src="@/assets/ac-header-icon-2.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div v-if="isMap" class="w-full min-h-screen lg:w-[50%]">
      <ProjectMap
        :phase-details="props.phaseDetails"
        @toggle-project-map="toggleProjectMap"
      />
    </div>
  </div>
</template>
