<script setup lang="ts">
import LoginLayout from "@/layouts/LoginLayout.vue";
import {
  GoogleSignInButton,
  useOneTap,
  type CredentialResponse,
} from "vue3-google-signin";
import { createClient } from "@supabase/supabase-js";
import { useUserStore } from "@/stores/user";
import { toast } from "vue3-toastify";
import router from "@/router";

const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL || "",
  process.env.VUE_APP_SUPABASE_API_KEY || ""
);

// handle success event
const handleLoginSuccess = async (response: CredentialResponse) => {
  if (response.credential !== null) {
    const { data, error } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: response.credential!,
    });
    if (error) {
      toast.error(error.message);
      return;
    }
    if (data && data.session) {
      let userStore = useUserStore();
      userStore.setUser(
        data.user.user_metadata.full_name
          ? data.user.user_metadata.full_name
          : data.user.user_metadata.name,
        data.user.email
      );
      userStore.setTokens(
        data.session.access_token,
        data.session.refresh_token
      );

      router.push("/");
    }
  }
};

// handle an error event
const handleLoginError = () => {
  console.error("Login failed");
};

// initialize One Tap
useOneTap({
  onSuccess: handleLoginSuccess,
  onError: handleLoginError,
  cancelOnTapOutside: true,
  // options
});
</script>

<template>
  <LoginLayout>
    <div class="flex flex-col items-start">
      <!-- Smalt logo -->
      <div class="mb-[60px] md:mb-[118px]">
        <img src="@/assets/smalt-logo-login.svg" alt="Smalt logo" />
      </div>
      <!-- Main heading -->
      <h1
        class="text-[#FFFFFF] text-[42px] leading-[50px] tracking-[0.01em] pb-[41px] font-bold"
      >
        Be welcomed to <br />
        the <span class="text-[#C6C7F8]">QC-dash</span>
      </h1>
      <!-- Sign in -->
      <GoogleSignInButton
        shape="pill"
        @success="handleLoginSuccess"
        @error="handleLoginError"
      ></GoogleSignInButton>
    </div>
  </LoginLayout>
</template>
