<script setup lang="ts">
import MainLayout from "@/layouts/MainLayout.vue";
import ProjectOverview from "@/components/Project/ProjectOverview.vue";
import MainSidebar from "@/components/Sidebar/MainSidebar.vue";
import { onMounted, ref } from "vue";
import client from "@/utils/axios";
import { useRoute } from "vue-router";
import { Project } from "@/types";

const selectedPhase = ref();
const isLoading = ref(false);

const onPhaseSelect = (phaseId: number) => {
  selectedPhase.value = phaseId;
};

const route = useRoute();
const id = route.params.id;
const projectDetails = ref<Project | null>(null);

// Fetch data
const getProjectDetails = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/projects/${id}`);
    projectDetails.value = response.data;
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
onMounted(() => {
  getProjectDetails();
});
//
</script>

<template>
  <MainLayout>
    <div class="flex justify-center relative">
      <div class="flex absolute left-0 top-0">
        <MainSidebar
          :is-loading="isLoading"
          :phase-progress="projectDetails?.phases"
          :phase-steps="[]"
          :selected-phase="selectedPhase"
          @on-phase-select="onPhaseSelect"
          :is-link="true"
        />
      </div>
      <div class="w-full xl:w-[934px] ml-0 lg:ml-[160px] xl:ml-0">
        <ProjectOverview :project-details="projectDetails" />
      </div>
    </div>
  </MainLayout>
</template>
