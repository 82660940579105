import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/Login/LoginView.vue";
import ProjectDetails from "@/views/projects/ProjectDetails.vue";
import PhasePage from "@/views/projects/PhasePage.vue";
import { useUserStore } from "@/stores/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/projects/:id",
    name: "Projects",
    component: ProjectDetails,
  },
  {
    path: "/projects/:id/phase/:phaseId",
    name: "Phase",
    component: PhasePage,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  if (to.name !== "Login") {
    if (userStore.accessToken === "") {
      next({ name: "Login" });
    }
  } else {
    if (userStore.accessToken !== "") {
      next({ name: "Home" });
    }
  }

  next();
});

export default router;
