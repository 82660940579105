<script setup lang="ts">
import { defineProps } from "vue";

type Props = {
  customerName: string;
  meister: string;
  costCarrierCode: string;
  nextTermin: any;
};

const props = defineProps<Props>();
</script>

<template>
  <div class="my-3 grid grid-cols-1 md:grid-cols-3 gap-[12.5px]">
    <div
      class="flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]"
    >
      <div class="flex items-center gap-2">
        <div class="flex items-center justify-center">
          <img src="@/assets/project-details-three-card-image-1.svg" alt="" />
        </div>
        <h3 class="text-lg text-primary-text-color font-semibold">
          Responsible Meister
        </h3>
      </div>
      <h4
        class="text-lg leading-[22.2px] tracking-[0.01rem] text-primary-text-color font-normal"
      >
        {{ props.meister ? props.meister : "" }}
      </h4>
    </div>
    <div
      class="flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]"
    >
      <div class="flex items-center gap-2">
        <div class="flex items-center justify-center">
          <img src="@/assets/code-brackets.svg" alt="" />
        </div>
        <h3 class="text-lg text-primary-text-color font-semibold">
          Cost Carrier Code
        </h3>
      </div>
      <div class="flex items-center justify-between">
        <span
          class="text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
          >{{ props.costCarrierCode ? props.costCarrierCode : "" }}.</span
        >
        <img class="cursor-pointer" src="@/assets/edit-copy.svg" alt="" />
      </div>
    </div>
    <div
      class="flex flex-col gap-3 p-3 bg-white rounded-primary-radius shadow-[0px_1px_13px_rgba(57,56,64,0.1)]"
    >
      <div class="flex items-center gap-2">
        <div class="flex items-center justify-center">
          <img src="@/assets/calendar-frame.svg" alt="" />
        </div>
        <h3 class="text-lg text-primary-text-color font-semibold">
          Next appointment
        </h3>
      </div>
      <!-- Date -->
      <div class="flex items-center justify-between">
        <span
          class="text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
          >{{
            props.nextTermin
              ? new Date(props.nextTermin?.start).toLocaleDateString("DE")
              : "-"
          }}</span
        >
        <span
          class="text-lg text-primary-text-color font-normal leading-[22.2px] tracking-[0.01rem]"
        >
          {{ props.nextTermin ? props.nextTermin?.name : "" }}</span
        >
      </div>
    </div>
  </div>
</template>
