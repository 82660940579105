export const getProjectStatusColor = (statusName: any) => {
  switch (statusName) {
    case "qc":
      return "#C6C7F8";
    case "abnahme":
      return "#A1E3CB";
    default:
      return "#E3E1EA";
  }
};
export const getTerminStatusColor = (status: string) => {
  switch (status) {
    case "open":
      return "#C6C7F8";
    case "proposed":
      return "#BAEDBD";
    case "confirmed":
      return "#BAEDBD";
    case "in_progress":
      return "#BAEDBD";
    case "completed":
      return "#BAEDBD";
    case "failed":
      return "#C6C7F8";
    default:
      return "#BAEDBD";
  }
};

export const formatStatusName = (status: string) => {
  return status.charAt(0).toUpperCase() + status.slice(1).replace(/_/g, " ");
};

// export const getDownloadStatusColor = (status: string) => {
//   switch (status) {
//     case "in-progress":
//       return "#A8C5DA";
//     case "ready-to-download":
//       return "#B1E3FF";
//     default:
//       return "#E5ECF6";
//   }
// };

// export const getDownloadStatusName = (status: string) => {
//   switch (status) {
//     case "in-progress":
//       return "In Progress";
//     case "ready-to-download":
//       return "Ready to Download";
//     case "downloaded":
//       return "Downloaded";
//     default:
//       return status;
//   }
// };
