<script setup lang="ts">
import VuePdfEmbed from "vue-pdf-embed";
import "vue-pdf-embed/dist/styles/annotationLayer.css";
import "vue-pdf-embed/dist/styles/textLayer.css";
import { defineEmits, defineProps } from "vue";

type Props = {
  phaseDetails: any;
};

const props = defineProps<Props>();

type Emits = {
  (event: "toggleProjectMap"): void;
};

const emits = defineEmits<Emits>();
</script>

<template>
  <div
    class="h-[calc(100vh-46px)] w-full bg-[#E5E4E2] p-3 rounded-primary-radius shadow-primary-box-shadow overflow-y-auto"
  >
    <div class="flex items-center justify-between mb-3">
      <h3
        class="text-base text-primary-text-color leading-5 tracking-[0.01rem] font-semibold"
      >
        Baumappe
      </h3>
      <div
        @click="emits('toggleProjectMap')"
        class="cursor-pointer flex items-center justify-center"
      >
        <img src="@/assets/close-icon-2.svg" alt="" />
      </div>
    </div>
    <div v-if="props.phaseDetails?.project?.baummape">
      <VuePdfEmbed
        annotation-layer
        text-layer
        :source="props.phaseDetails?.project?.baummape"
      />
    </div>
  </div>
</template>
