<script setup lang="ts"></script>

<template>
  <div
    class="w-full h-screen flex bg-[#EFEEEC] p-4 sm:p-6 overflow-y-hidden font-sans"
  >
    <div class="w-full">
      <slot />
    </div>
  </div>
</template>
