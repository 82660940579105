export const textTransform = (text: string) => {
  const transformedText = text
    .split("_")
    .map((word, index) =>
      index === 0 || word.length === 2
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");

  switch (text) {
    case "preufbericht":
    case "baummape":
    case "ac_report":
    case "dc_report":
    case "abnahme_protokoll":
      return transformedText;
    default:
      return transformedText;
  }
};
